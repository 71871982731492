/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c2923072-43a4-4ab9-9e2f-d9d585bbd732",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_YeKlpzaaa",
    "aws_user_pools_web_client_id": "24mcpmff55om41j9alaanb9r43",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://np32t64ljjbynhtwpnlfzeuxqe.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "salesvoltProdApi",
            "endpoint": "https://c9k70lsuwg.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://ozehvv06bg.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "SalesVoltPrivate",
            "endpoint": "https://polvztgxak.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "SalesVoltPublic",
            "endpoint": "https://3v2n0n6m62.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "files185107-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "salesvolt-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1r1dtjsgie7dj.cloudfront.net",
    "aws_mobile_analytics_app_id": "286d1a57c89a47d19dc2244c5c48eb91",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
