import Vue from "vue";
import Router from "vue-router";
import {API, Auth, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";

const Home = () => import("../views/Home");
const Dashboard = () => import("../modules/dashboard/Dashboard.vue");
const LeadDashboard = () => import("../modules/lead-dashboard/LeadDashboard.vue");
const MonthToDate = () => import("../modules/month-to-date/MonthToDate.vue");
const Reports = () => import("../modules/reports/Reports.vue");
const NinetyDays = () => import("../modules/ninety-days/NinetyDays.vue");
const Profile = () => import("../modules/profile/Profile.vue");
const NewForm = () => import("../modules/new-form/NewForm.vue");
const RBLForm = () => import("../modules/new-form/_components/RBLForm.vue");
const HDFCForm = () => import("../modules/new-form/_components/HDFCForm.vue");
const YESForm = () => import("../modules/new-form/_components/YESForm.vue");
const SearchForms = () => import("../modules/search-forms/SearchForms.vue");
const CustomerForm = () => import("../views/CustomerForm.vue");
const UploadDocuments = () => import("../views/UploadDocuments.vue");
const UserManagement = () => import("../modules/user-management/UserManagement.vue");
const SystemConfiguration = () => import("../modules/system-configuration/SystemConfiguration.vue");
const Upload = () => import("../modules/upload/Upload.vue");
const LeadManagement = () => import("../modules/lead-management/LeadManagement.vue");
const Prospects = () => import("../modules/prospects/Prospects.vue");
const AlreadyApproved = () => import("../modules/already-approved/AlreadyApproved.vue");

Vue.use(Router);

async function requireAuth(to, from, next) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const {
      data: {getUser}
    } = await API.graphql(graphqlOperation(queries.getUser, {id: user.attributes.sub}));
    if (!getUser) {
      if (to.name !== 'profile') {
        next('/profile');
      }
    } else {
      if (getUser.jobTitle && getUser.jobTitle !== '') {
        const {
          data: {getTitle}
        } = await API.graphql(graphqlOperation(queries.getTitle, {id: getUser.jobTitle}));
        if (getTitle) {
          if (getTitle.access && getTitle.access !== '') {
            if (_.includes(getTitle.pages, to.name)) {
              next();
            } else {
              next('/');
            }
          } else {
            if (to.name !== 'profile') {
              next('/profile');
            }
          }
        } else {
          if (to.name !== 'profile') {
            next('/profile');
          }
        }
      } else {
        if (to.name !== 'profile') {
          next('/profile');
        }
      }
    }
    next();
  } catch (e) {
    next('/');
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      beforeEnter: requireAuth
    },
    {
      path: "/lead-dashboard",
      name: "leadDashboard",
      component: LeadDashboard,
      beforeEnter: requireAuth
    },
    {
      path: '/prospects',
      name: 'prospects',
      component: Prospects,
      beforeEnter: requireAuth
    },
    {
      path: "/month",
      name: "month",
      component: MonthToDate,
      beforeEnter: requireAuth
    },
    {
      path: '/lead-management',
      name: 'leadManagement',
      component: LeadManagement,
      beforeEnter: requireAuth
    },
    {
      path: '/upload',
      name: 'upload',
      component: Upload,
      beforeEnter: requireAuth
    },
    {
      path: '/search',
      name: 'search',
      component: SearchForms,
      beforeEnter: requireAuth
    },
    {
      path: "/reports",
      name: "reports",
      component: Reports,
      beforeEnter: requireAuth
    },
    {
      path: "/already-approved",
      name: "alreadyApproved",
      component: AlreadyApproved,
      beforeEnter: requireAuth
    },
    {
      path: "/ninetyDays",
      name: "ninetyDays",
      component: NinetyDays,
      beforeEnter: requireAuth
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: requireAuth
    },
    {
      path: '/new-form',
      name: 'newForm',
      component: NewForm,
      beforeEnter: requireAuth,
      children: [
        {
          path: 'rbl',
          name: 'rbl',
          component: RBLForm
        },
        {
          path: 'hdfc',
          name: 'hdfc',
          component: HDFCForm
        },
        {
          path: 'yes',
          name: 'yes',
          component: YESForm
        }
      ]
    },
    {
      path: '/customer-form',
      name: 'customerForm',
      component: CustomerForm
    },
    {
      path: '/upload-documents',
      name: 'uploadDocuments',
      component: UploadDocuments
    },
    {
      path: '/user-management',
      name: 'users',
      component: UserManagement,
      beforeEnter: requireAuth
    },
    {
      path: '/system-configuration',
      name: 'system',
      component: SystemConfiguration,
      beforeEnter: requireAuth
    },
    // catch all redirect
    {
      path: '*',
      redirect: '/'
    }
  ]
});
