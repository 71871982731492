import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    appVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')).version,
    username: '',
    cognitoUser: null,
    loggedInUser: null,
    loadingState: true,
    signedIn: false,
    logger: {},
    settings: [],
    titles: [],
    branches: [],
    users: [],
    statuses: [],
    products: [],
    campaigns: [],
    allCampaigns: [],
    enabledSources: [],
    forms: [],
    dailyForms: [],
    mtdForms: [],
    refreshMtdForms: false,
    leadFeedbacks: [],
    leads: [],
    allLeads: [],
    formFilterTerm: '',
    searchFormTerm: '',
    updateSubscriptionForm: null,
    deleteSubscriptionForm: null,
    activeDashboardBranchId: null
  },
  mutations: {
    UPDATE_USERNAME(state, username) {
      Vue.set(state, 'username', username);
    },
    UPDATE_COGNITO_USER(state, user) {
      Vue.set(state, 'cognitoUser', user);
    },
    UPDATE_LOGGED_IN_USER(state, user) {
      Vue.set(state, 'loggedInUser', user);
    },
    UPDATE_LOADING(state, loadingState) {
      Vue.set(state, 'loadingState', loadingState);
    },
    UPDATE_SIGNED_IN(state, signedInState) {
      Vue.set(state, 'signedIn', signedInState);
    },
    UPDATE_LOGGER(state, logger) {
      Vue.set(state, 'logger', logger);
    },
    UPDATE_SETTINGS(state, settings) {
      Vue.set(state, 'settings', settings);
    },
    UPDATE_TITLES(state, titles) {
      Vue.set(state, 'titles', titles);
    },
    UPDATE_BRANCHES(state, branches) {
      Vue.set(state, 'branches', branches);
    },
    UPDATE_USERS(state, users) {
      Vue.set(state, 'users', users);
    },
    UPDATE_STATUSES(state, statuses) {
      Vue.set(state, 'statuses', statuses);
    },
    UPDATE_PRODUCTS(state, products) {
      Vue.set(state, 'products', products);
    },
    UPDATE_CAMPAIGNS(state, campaigns) {
      Vue.set(state, 'campaigns', campaigns);
    },
    UPDATE_ALL_CAMPAIGNS(state, campaigns) {
      Vue.set(state, 'allCampaigns', campaigns);
    },
    UPDATE_ENABLED_SOURCES(state, sources) {
      Vue.set(state, 'enabledSources', sources);
    },
    UPDATE_FORMS(state, forms) {
      Vue.set(state, 'forms', forms);
    },
    UPDATE_DAILY_FORMS(state, forms) {
      Vue.set(state, 'dailyForms', forms);
    },
    UPDATE_MTD_FORMS(state, forms) {
      Vue.set(state, 'mtdForms', forms);
    },
    UPDATE_REFRESH_MTD_FORMS(state, refresh) {
      Vue.set(state, 'refreshMtdForms', refresh);
    },
    UPDATE_LEAD_FEEDBACKS(state, feedbacks) {
      Vue.set(state, 'leadFeedbacks', feedbacks);
    },
    UPDATE_LEADS(state, leads) {
      Vue.set(state, 'leads', _.sortBy(leads, ['createdAtEpoch']));
    },
    UPDATE_ALL_LEADS(state, leads) {
      Vue.set(state, 'allLeads', leads);
    },
    UPDATE_FORM_FILTER_TERM(state, formFilterTerm) {
      Vue.set(state, 'formFilterTerm', formFilterTerm);
    },
    UPDATE_SEARCH_FORM_TERM(state, term) {
      Vue.set(state, 'searchFormTerm', term);
    },
    UPDATE_UPDATE_SUBSCRIPTION_FORM(state, form) {
      Vue.set(state, 'updateSubscriptionForm', form);
    },
    UPDATE_DELETE_SUBSCRIPTION_FORM(state, form) {
      Vue.set(state, 'deleteSubscriptionForm', form);
    },
    UPDATE_ACTIVE_DASHBOARD_BRANCH_ID(state, branchId) {
      Vue.set(state, 'activeDashboardBranchId', branchId);
    }
  },
  getters: {
    getAppVersion(state) {
      return state.appVersion;
    },
    getUsername(state) {
      return state.username;
    },
    getCognitoUser(state) {
      return state.cognitoUser;
    },
    getLoggedInUser(state) {
      return state.loggedInUser;
    },
    getLoadingState(state) {
      return state.loadingState;
    },
    getSignedIn(state) {
      return state.signedIn;
    },
    getLogger(state) {
      return state.logger;
    },
    getSettings(state) {
      return state.settings;
    },
    getTitles(state) {
      return state.titles;
    },
    getBranches(state) {
      return state.branches;
    },
    getUsers(state) {
      return state.users;
    },
    getStatuses(state) {
      return state.statuses;
    },
    getProducts(state) {
      return state.products;
    },
    getCampaigns(state) {
      return state.campaigns;
    },
    getAllCampaigns(state) {
      return state.allCampaigns;
    },
    getEnabledSources(state) {
      return state.enabledSources;
    },
    getForms(state) {
      return state.forms;
    },
    getDailyForms(state) {
      return state.dailyForms;
    },
    getMtdForms(state) {
      return state.mtdForms;
    },
    getRefreshMtdForms(state) {
      return state.refreshMtdForms;
    },
    getLeadFeedbacks(state) {
      return state.leadFeedbacks;
    },
    getLeads(state) {
      return state.leads;
    },
    getAllLeads(state) {
      return state.allLeads;
    },
    getFormFilterTerm(state) {
      return state.formFilterTerm;
    },
    getSearchFormTerm(state) {
      return state.searchFormTerm;
    },
    getUpdateSubscriptionForm(state) {
      return state.updateSubscriptionForm;
    },
    getDeleteSubscriptionForm(state) {
      return state.deleteSubscriptionForm;
    },
    getActiveDashboardBranchId(state) {
      return state.activeDashboardBranchId;
    }
  }
});
