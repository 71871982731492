<template>
  <div class="navbar">
    <div class="navbar-inner">
      <div class="sidebar-pusher">
        <a
          class="waves-effect waves-button waves-classic push-sidebar"
          href="javascript:void(0);"
        >
          <i class="fas fa-bars" />
        </a>
      </div><!-- Burger Menu For Mobile View -->
      <div class="logo-box">
        <a
          class="logo-text"
          href="#"
        >
          <span>SalesVolt</span>
          <span class="app-version">{{ getAppVersion }}</span>
        </a>
        <div class="search-button">
          <a
            class="waves-effect waves-button waves-classic show-search"
            href="javascript:void(0);"
          >
            <i class="fas fa-search" />
          </a>
        </div>
      </div><!-- Logo Box -->
      <div
        id="navbar-topmenu"
        class="topmenu-outer"
      >
        <div class="top-menu">
          <ul class="nav navbar-nav navbar-left">
            <li>
              <a
                class="waves-effect waves-button waves-classic sidebar-toggle"
                href="javascript:void(0);"
              >
                <i class="fas fa-bars" />
              </a>
            </li>
            <li>
              <a
                class="waves-effect waves-button waves-classic toggle-fullscreen"
                href="javascript:void(0);"
              >
                <i class="fas fa-expand" />
              </a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li v-if="hasSearchAccess">
              <a
                class="waves-effect waves-button waves-classic show-search"
                href="javascript:void(0);"
              >
                <i class="fas fa-search" />
              </a>
            </li>
            <li class="dropdown">
              <a
                class="dropdown-toggle waves-effect waves-button waves-classic"
                data-toggle="dropdown"
                href="#"
              >
                <i class="fas fa-bell" />
                <span class="badge badge-success pull-right">3</span>
              </a>
              <ul
                class="dropdown-menu title-caret dropdown-lg"
                role="menu"
              >
                <li>
                  <p class="drop-title">
                    You have 3 pending tasks !
                  </p>
                </li>
                <li class="dropdown-menu-list slimscroll tasks">
                  <!--<ul class="list-unstyled">-->
                  <!--<li>-->
                  <!--<a href="#">-->
                  <!--<div class="task-icon badge badge-success">-->
                  <!--<i class="icon-user"></i>-->
                  <!--</div>-->
                  <!--<span class="badge badge-roundless badge-default pull-right">1min ago</span>-->
                  <!--<p class="task-details">New user registered.</p>-->
                  <!--</a>-->
                  <!--</li>-->
                  <!--<li>-->
                  <!--<a href="#">-->
                  <!--<div class="task-icon badge badge-danger">-->
                  <!--<i class="icon-energy"></i>-->
                  <!--</div>-->
                  <!--<span class="badge badge-roundless badge-default pull-right">24min ago</span>-->
                  <!--<p class="task-details">Database error.</p>-->
                  <!--</a>-->
                  <!--</li>-->
                  <!--</ul>-->
                </li>
                <li class="drop-all">
                  <a
                    class="text-center"
                    href="#"
                  >All Tasks</a>
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a
                class="dropdown-toggle waves-effect waves-button waves-classic"
                data-toggle="dropdown"
                href="#"
              >
                <span class="user-name">{{ _getAttributes.userName }} <i class="fas fa-angle-down" /></span>
                <img
                  :src="getProfilePicture"
                  alt="profile"
                  class="img-circle img-cover avatar"
                  height="40"
                  width="40"
                >
              </a>
              <ul
                class="dropdown-menu dropdown-list"
                role="menu"
              >
                <li role="presentation">
                  <router-link :to="{name: 'profile'}">
                    <i class="fas fa-user m-r-xs" />
                    Profile
                  </router-link>
                </li>
                <li role="presentation">
                  <a href="#">
                    <i class="far fa-calendar-alt m-r-xs" />
                    Calendar
                  </a>
                </li>
                <li
                  class="divider"
                  role="presentation"
                />
                <li role="presentation">
                  <a href="#">
                    <i class="fas fa-lock m-r-xs" />
                    Lock screen
                  </a>
                </li>
                <li role="presentation">
                  <a
                    class="sign-out"
                    href="#"
                    @click="signOut"
                  >
                    <i class="fas fa-sign-out-alt m-r-xs" />
                    Log out
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                class="sign-out log-out waves-effect waves-button waves-classic"
                href="#"
                @click="signOut"
              >
                <span>Log out<i class="fas fa-sign-out-alt m-l-xs" /></span>
              </a>
            </li>
          </ul><!-- Nav -->
        </div><!-- Top Menu -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Analytics, Auth, Storage} from 'aws-amplify';

export default {
  name: "AppNavigation",
  asyncComputed: {
    getProfilePicture: {
      get() {
        if (this.getLoggedInUser && this.getLoggedInUser !== '' && this.getLoggedInUser.picture && this.getLoggedInUser.picture !== '') {
          return Storage.get(this.getLoggedInUser.picture, {level: 'protected'});
        } else {
          return require('../assets/user.png');
        }
      },
      default() {
        return require('../assets/user.png');
      }
    }
  },
  computed: {
    ...mapGetters([
      "getAppVersion",
      "getLogger",
      "getLoggedInUser",
      "getTitles"
    ]),
    _getAttributes() {
      let attributes = {
        userName: ''
      };
      if (this.getLoggedInUser && this.getLoggedInUser !== '') {
        attributes.userName = (this.getLoggedInUser.nickname && this.getLoggedInUser.nickname !== '') ?
            _.capitalize(this.getLoggedInUser.nickname) : attributes.userName;

        if (attributes.userName === '') {
          attributes.userName = (this.getLoggedInUser.firstName && this.getLoggedInUser.firstName !== '') ?
              _.capitalize(this.getLoggedInUser.firstName) : attributes.userName;
          attributes.userName = (this.getLoggedInUser.lastName && this.getLoggedInUser.lastName !== '') ?
              attributes.userName + ' ' + _.capitalize(this.getLoggedInUser.lastName) : attributes.userName;
        }
      }
      return attributes;
    },
    hasSearchAccess: function () {
      if (this.getLoggedInUser) {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          return _.includes(jobTitle.pages, 'search');
        } else {
          return false;
        }

      } else {
        return false;
      }
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    signOut: function () {
      this.updatePinpoint();
      Auth.signOut()
          .then(() => {
            this.getLogger.info("signout success");
            this.$router.push({name: 'home'})
                .catch(e => {
                  console.error(e);
                  window.location.reload();
                });
            return AmplifyEventBus.$emit("authState", "signedOut");
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    async updatePinpoint() {
      try {
        await Analytics.stopSession();
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style scoped>

</style>
