<template>
  <div
    id="sidebar"
    class="page-sidebar sidebar"
  >
    <Slide
      :burger-icon="false"
      :is-open="sideMenuOpen"
      @closeMenu="handleCloseMenu"
    >
      <div class="profile">
        <img
          :src="getProfilePicture"
          alt="profile"
          class="img-circle img-cover"
          height="60"
          width="60"
        >
        <span>{{ _getAttributes.userName }}</span>
      </div>
      <router-link
        :to="{name: 'prospects'}"
      >
        <i class="fas fa-star" />
        <span>Prospects</span>
      </router-link>
      <a href="#">
        <i class="fas fa-bell" />
        <span>Alerts</span></a>
      <a href="#">
        <i class="fas fa-envelope" /><span>Messages</span>
      </a>
      <router-link
        :to="{name: 'profile'}"
      >
        <i class="fas fa-user" />
        <span>Profile</span>
      </router-link>
      <a
        class="sign-out"
        href="#"
        @click="signOut"
      >
        <i class="fas fa-sign-out-alt" />
        <span>Log Out</span>
      </a>
    </Slide>
    <main id="page-wrap" />
    <div class="page-sidebar-inner slimscroll">
      <div class="sidebar-header">
        <div class="sidebar-profile">
          <a
            id="profile-menu-link"
            href="#"
          >
            <div class="sidebar-profile-image">
              <img
                :src="getProfilePicture"
                alt="profile"
                class="img-circle img-cover img-responsive sidebar-bm-button"
                @click="openSideMenu"
              >
              <!--TODO: FIGURE OUT HOW TO GET OFFCANVAS TO WORK WITHOUT MODIFYING NODE-MODULES ('&& !e.target.className.includes('sidebar-bm-button')')-->
            </div>
            <div class="sidebar-profile-details">
              <span>{{ _getAttributes.userName }}<br><small>{{ _getAttributes.jobTitle }}</small></span>
            </div>
          </a>
        </div>
      </div>
      <ul class="menu accordion-menu">
        <li
          v-if="includesAccess('dashboard')"
          :class="currentRoute === 'dashboard' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'home'}"
          >
            <span class="menu-icon fas fa-2x fa-home" />
            <p class="sidebar-page-title">
              Dashboard
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('leadDashboard')"
          :class="currentRoute === 'leadDashboard' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'leadDashboard'}"
          >
            <span class="menu-icon fas fa-2x fa-phone-volume" />
            <p class="sidebar-page-title">
              Lead Dashboard
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('prospects')"
          :class="currentRoute === 'prospects' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'prospects'}"
          >
            <span class="menu-icon fas fa-2x fa-star" />
            <p class="sidebar-page-title">
              Prospects
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('month')"
          :class="currentRoute === 'month' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'month'}"
          >
            <span class="menu-icon fas fa-2x fa-clock" />
            <p class="sidebar-page-title">
              Month To Date
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('leadManagement')"
          :class="currentRoute === 'leadManagement' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'leadManagement'}"
          >
            <span class="menu-icon fas fa-2x fa-funnel-dollar" />
            <p class="sidebar-page-title">
              Lead Management
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('upload')"
          :class="currentRoute === 'upload' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'upload'}"
          >
            <span class="menu-icon fas fa-2x fa-file-upload" />
            <p class="sidebar-page-title">
              Upload
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('search')"
          :class="currentRoute === 'search' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'search'}"
          >
            <span class="menu-icon fas fa-2x fa-search" />
            <p class="sidebar-page-title">
              Search Forms
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('reports')"
          :class="currentRoute === 'reports' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'reports'}"
          >
            <span class="menu-icon fas fa-2x fa-file-invoice" />
            <p class="sidebar-page-title">
              Reports
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('alreadyApproved')"
          :class="currentRoute === 'alreadyApproved' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'alreadyApproved'}"
          >
            <span class="menu-icon fas fa-2x fa-calendar-check" />
            <p class="sidebar-page-title">
              Already Approved
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('ninetyDays')"
          :class="currentRoute === 'ninetyDays' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'ninetyDays'}"
          >
            <span class="menu-icon far fa-2x fa-calendar-alt" />
            <p class="sidebar-page-title">
              90 Days
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('users')"
          :class="currentRoute === 'users' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'users'}"
          >
            <span class="menu-icon fas fa-2x fa-magnet" />
            <p class="sidebar-page-title">
              User Management
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('system')"
          :class="currentRoute === 'system' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'system'}"
          >
            <span class="menu-icon fas fa-2x fa-cog" />
            <p class="sidebar-page-title">
              System Configuration
            </p>
          </router-link>
        </li>
        <li
          v-if="includesAccess('newForm')"
          :class="currentRoute === 'newForm' ? 'active' : ''"
        >
          <router-link
            :to="{name: 'newForm'}"
          >
            <span class="menu-icon far fa-2x fa-edit" />
            <p class="sidebar-page-title">
              New Form
            </p>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- Page Sidebar Inner -->
  </div>
</template>

<script>
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Analytics, Auth, Storage} from 'aws-amplify';
import {mapGetters} from "vuex";
import {Slide} from "vue-burger-menu";

export default {
  name: "AppSidebar",
  components: {
    Slide
  },
  data() {
    return {
      sideMenuOpen: false
    };
  },
  asyncComputed: {
    getProfilePicture: {
      get() {
        if (this.getLoggedInUser && this.getLoggedInUser !== '' && this.getLoggedInUser.picture && this.getLoggedInUser.picture !== '') {
          return Storage.get(this.getLoggedInUser.picture, {level: 'protected'});
        } else {
          return require('../assets/user.png');
        }
      },
      default() {
        return require('../assets/user.png');
      }
    }
  },
  computed: {
    ...mapGetters([
      "getLogger",
      "getLoggedInUser",
      "getTitles"
    ]),
    _getAttributes() {
      let attributes = {
        userName: '',
        jobTitle: 'No Job Title',
        jobType: '',
        pages: []
      };
      if (this.getLoggedInUser && this.getLoggedInUser !== '') {
        if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === this.getLoggedInUser.jobTitle;
          });
          if (jobTitle) {
            attributes.jobTitle = jobTitle.name;
            attributes.jobType = jobTitle.access;
            attributes.pages = jobTitle.pages;
          }
        }

        attributes.userName = (this.getLoggedInUser.firstName && this.getLoggedInUser.firstName !== '') ?
            _.capitalize(this.getLoggedInUser.firstName) : attributes.userName;
        attributes.userName = (this.getLoggedInUser.lastName && this.getLoggedInUser.lastName !== '') ?
            attributes.userName + ' ' + _.capitalize(this.getLoggedInUser.lastName) : attributes.userName;
      }
      return attributes;
    },
    includesAccess: function () {
      return (page) => {
        return _.includes(this._getAttributes.pages, page);
      }
    },
    currentRoute: function () {
      return this.$route.name;
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    signOut: function () {
      this.updatePinpoint();
      Auth.signOut()
          .then(() => {
            this.sideMenuOpen = false;
            this.getLogger.info("signout success");
            this.$router.push({name: 'home'})
                .catch(e => {
                  console.error(e);
                  window.location.reload();
                });
            $('body').removeClass('loadedbm-overlay');
            $('body').addClass('loaded');
            return AmplifyEventBus.$emit("authState", "signedOut");
          })
          .catch((e) => {
            $('body').removeClass('loadedbm-overlay');
            $('body').addClass('loaded');
            this.setMessage('v-notify-error', "Error!", e);
          });
    },
    handleCloseMenu() {
      this.sideMenuOpen = false;
    },
    openSideMenu() {
      this.sideMenuOpen = true;
    },
    async updatePinpoint() {
      try {
        await Analytics.stopSession();
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style scoped/>
