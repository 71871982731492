<template>
  <div
    id="app"
    class="page-header-fixed"
  >
    <div id="overlay" />
    <div v-if="!isCustomerForm">
      <router-view v-if="!getSignedIn" />
      <main
        v-else
        class="page-content content-wrap"
      >
        <div class="search-form">
          <div class="input-group">
            <input
              id="search-form-term"
              class="form-control search-input"
              name="search"
              placeholder="Search..."
              type="text"
              @keyup.enter.stop.prevent="searchFormTermUpdate"
            >
            <span class="input-group-btn">
              <button
                class="btn btn-default close-search waves-effect waves-button waves-classic"
                type="button"
              >
                <i class="fas fa-times" />
              </button>
            </span>
          </div><!-- Input Group -->
        </div><!-- Search Form -->
        <AppNavigation />
        <AppSidebar />
        <div class="page-inner">
          <router-view />
          <div
            id="page-footer"
            class="page-footer"
          >
            <p class="no-s">
              2019 &copy; SalesVolt by SimplyHashing.
            </p>
          </div>
        </div>
      </main>
    </div>
    <div v-else>
      <div class="page-inner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import AppNavigation from "./components/AppNavigation";
import AppSidebar from "./components/AppSidebar";
import {Analytics, API, Auth, graphqlOperation, Storage} from "aws-amplify";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import * as customQueries from "./graphql/customQueries";
import * as subscriptions from "./graphql/subscriptions";
import {pushNotificationCategories} from "./constants";
// import pdfmake from "pdfmake/build/pdfmake.min";
// import pdfmakeVf from "pdfmake/build/vfs_fonts";
// pdfmake.vfs = pdfmakeVf.pdfMake.vfs;

export default {
  name: "App",
  components: {
    AppNavigation,
    AppSidebar
  },
  data() {
    return {
      createFormSubscription: null,
      deleteFormSubscription: null,
      createSettingSubscription: null,
      createTitleSubscription: null,
      createStatusSubscription: null,
      createBranchSubscription: null,
      updateSettingSubscription: null,
      updateTitleSubscription: null,
      updateStatusSubscription: null,
      updateBranchSubscription: null,
      updateProductSubscription: null,
      updateFormSubscription: null,
      createUserSubscription: null,
      updateUserSubscription: null,
      createCampaignSubscription: null,
      updateCampaignSubscription: null,
      deleteCampaignSubscription: null,
      updateSourceSubscription: null,
      createLeadSubscription: null,
      createLeadFeedbackSubscription: null,
      updateLeadFeedbackSubscription: null,
    };
  },
  computed: {
    ...mapGetters([
      "getSignedIn",
      "getLogger",
      "getLoggedInUser",
      "getSettings",
      "getTitles",
      "getBranches",
      "getUsers",
      "getStatuses",
      "getProducts",
      "getCampaigns",
      "getAllCampaigns",
      "getEnabledSources",
      "getForms",
      "getDailyForms",
      "getMtdForms",
      "getLeadFeedbacks",
      "getLeads",
      "getAllLeads",
      "getActiveDashboardBranchId"
    ]),
    ...mapState([
      "activeDashboardBranchId",
      "refreshMtdForms"
    ]),
    isCustomerForm: function () {
      return !this.$route.name || this.$route.name === 'customerForm' || this.$route.name === 'uploadDocuments';
    }
  },
  watch: {
    activeDashboardBranchId(newValue) {
      if (newValue && newValue !== '') {
        this.UPDATE_FORMS([]);
        this.fetchForms();
      }
    },
    refreshMtdForms(newValue) {
      if (newValue) {
        this.UPDATE_REFRESH_MTD_FORMS(false);
        this.UPDATE_MTD_FORMS([]);
        let filter = {};
        const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle
        }), 'access');
        if (currentUserJobTitle === 'User') {
          filter = {
            owner: {
              eq: this.getLoggedInUser.username
            }
          };
        }
        if (!_.includes(["Manager, Supervisor"], currentUserJobTitle)) {
          _(this.getStatuses)
              .filter((status) => {
                return _.includes(status.currentMonth, currentUserJobTitle);
              })
              .forEach((status) => {
                this.fetchFormsByStatusMtd(status.id, moment().startOf('month').format('X'), filter, currentUserJobTitle, null);
              });
        }
      }
    }
  },
  async beforeMount() {
    $.fn.dataTable.moment('dddd, MMMM Do YYYY h:mm A');
    $.fn.dataTable.moment('MMMM Do YYYY h:mm A');
    $.fn.dataTable.moment('DD-MM-YYYY');
    $.fn.dataTable.moment('YYYY-MM-DD');
    $.fn.dataTable.moment('h:mm A');

    Analytics.disable();
    Storage.configure({level: 'protected'});
    this.UPDATE_LOGGER(new this.$Amplify.Logger(this.$options.name));

    AmplifyEventBus.$on("authState", info => {
      if (info === "signedIn") {
        this.updateUser();
      } else if (info === "signedOut") {
        this.updatePinpointEndpoint(false);
        this.UPDATE_SIGNED_IN(false);
        this.UPDATE_LOGGED_IN_USER({});
        this.unsubscribePushSubscriptions();
        this.unsubscribeLists();
      }
    });
    window.localStorage.removeItem('reduxPersist::appsync');
    window.localStorage.removeItem('reduxPersist::appsync-metadata');
  },
  async mounted() {
    this.updateUser();
  },
  async updated() {
    $('.date-picker').datepicker({
      autoclose: true,
      format: "dd-mm-yyyy",
      endDate: "0d"
    });

    $('input[type=radio]').uniform();
    const body = $('#app');
    const logoBox = $('.navbar .logo-box a span:eq(0)');
    const logoVersion = $('.navbar .logo-box a span:eq(1)');
    const sidebar = $('.sidebar');
    const pageContent = $('.page-content');
    const themeSettings = $('.theme-settings');

    // Toggle Search
    $('.show-search').unbind().click(() => {
      $('.search-form').css('margin-top', '0');
      $('.search-input').focus();
    });

    $('.close-search').unbind().click(() => {
      $('.search-form').css('margin-top', '-60px');
    });

    // Fullscreen
    let fullScreenButton = document.getElementsByClassName("toggle-fullscreen");

    //Activate click listener
    for (let i = 0; i < fullScreenButton.length; i++) {
      fullScreenButton[i].addEventListener("click", () => {

        //Toggle fullscreen off, activate it
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen(); // Firefox
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(); // Chrome and Safari
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen(); // IE
          }

          //Toggle fullscreen on, exit fullscreen
        } else {

          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }

      });
    }

    // Panel Control
    $('.panel-collapse').unbind().click(() => {
      $(this).closest(".panel").children('.panel-body').slideToggle('fast');
    });

    $('.panel-remove').unbind().click(() => {
      $(this).closest(".panel").hide();
    });

    // Push Menu
    $('.push-sidebar').unbind().click(() => {
      let hidden = sidebar;

      if (hidden.hasClass('visible')) {
        hidden.removeClass('visible');
        $('.page-inner').removeClass('sidebar-visible');
      } else {
        hidden.addClass('visible');
        $('.page-inner').addClass('sidebar-visible');
      }
    });

    // .toggleAttr() Function
    $.fn.toggleAttr = function (a, b) {
      let c = (b === undefined);
      return this.each(() => {
        if ((c && !$(this).is("[" + a + "]")) || (!c && b)) $(this).attr(a, a);
        else $(this).removeAttr(a);
      });
    };

    // Sidebar Menu
    $('.sidebar .accordion-menu li .sub-menu').slideUp(0);
    $('.sidebar .accordion-menu li.open > .sub-menu').slideDown(0);
    $('.small-sidebar .sidebar .accordion-menu li.open .sub-menu').hide(0);
    $('.sidebar .accordion-menu > li.droplink > a').unbind().click(() => {
      if (!(body.hasClass('small-sidebar')) && (!body.hasClass('page-horizontal-bar')) && (!body.hasClass('hover-menu'))) {

        let menu = $('.sidebar .menu'),
            sub = $(this).next();

        menu.find('li').removeClass('open');
        $('.sub-menu').slideUp(200, () => {
          sidebarAndContentHeight();
        });
        sidebarAndContentHeight();

        if (!sub.is(':visible')) {
          $(this).parent('li').addClass('open');
          $(this).next('.sub-menu').slideDown(200, () => {
            sidebarAndContentHeight();
          });
        } else {
          sub.slideUp(200, () => {
            sidebarAndContentHeight();
          });
        }
        return false;
      }

      if ((body.hasClass('small-sidebar')) && (body.hasClass('page-sidebar-fixed'))) {
        let menu = $('.sidebar .menu'),
            sub = $(this).next();

        menu.find('li').removeClass('open');
        $('.sub-menu').slideUp(200, () => {
          sidebarAndContentHeight();
        });
        sidebarAndContentHeight();

        if (!sub.is(':visible')) {
          $(this).parent('li').addClass('open');
          $(this).next('.sub-menu').slideDown(200, () => {
            sidebarAndContentHeight();
          });
        } else {
          sub.slideUp(200, () => {
            sidebarAndContentHeight();
          });
        }
        return false;
      }
    });

    $('.sidebar .accordion-menu .sub-menu li.droplink > a').unbind().click(() => {
      let menu = $(this).parent().parent(),
          sub = $(this).next();

      menu.find('li').removeClass('open');
      sidebarAndContentHeight();

      if (!sub.is(':visible')) {
        $(this).parent('li').addClass('open');
        $(this).next('.sub-menu').slideDown(200, () => {
          sidebarAndContentHeight();
        });
      } else {
        sub.slideUp(200, () => {
          sidebarAndContentHeight();
        });
      }
      return false;
    });

    // Makes .page-inner height same as .page-sidebar height
    let sidebarAndContentHeight = () => {
      let content = $('.page-inner'),
          sidebar = $('.page-sidebar'),
          height,
          footerHeight = $('.page-footer').outerHeight();

      content.attr('style', 'min-height:' + sidebar.height() + 'px !important');

      if (body.hasClass('page-sidebar-fixed')) {
        height = sidebar.height() + footerHeight;
      } else {
        height = sidebar.height() + footerHeight;
        if (height < $(window).height()) {
          height = $(window).height();
        }
      }

      if (height >= content.height()) {
        content.attr('style', 'min-height:' + height + 'px !important');
      }
    };

    sidebarAndContentHeight();

    window.onresize = sidebarAndContentHeight;

    // Layout Settings
    let fixedHeaderCheck = document.querySelector('.fixed-header-check'),
        fixedSidebarCheck = document.querySelector('.fixed-sidebar-check'),
        horizontalBarCheck = document.querySelector('.horizontal-bar-check'),
        toggleSidebarCheck = document.querySelector('.toggle-sidebar-check'),
        boxedLayoutCheck = document.querySelector('.boxed-layout-check'),
        compactMenuCheck = document.querySelector('.compact-menu-check'),
        hoverMenuCheck = document.querySelector('.hover-menu-check'),
        defaultOptions = () => {

          if ((body.hasClass('small-sidebar')) && (toggleSidebarCheck.checked === 1)) {
            toggleSidebarCheck.unbind().click();
          }

          if (!(body.hasClass('page-header-fixed')) && (fixedHeaderCheck.checked === 0)) {
            fixedHeaderCheck.unbind().click();
          }

          if ((body.hasClass('page-sidebar-fixed')) && (fixedSidebarCheck.checked === 1)) {
            fixedSidebarCheck.unbind().click();
          }

          if ((body.hasClass('page-horizontal-bar')) && (horizontalBarCheck.checked === 1)) {
            horizontalBarCheck.unbind().click();
          }

          if ((body.hasClass('compact-menu')) && (compactMenuCheck.checked === 1)) {
            compactMenuCheck.unbind().click();
          }

          if ((body.hasClass('hover-menu')) && (hoverMenuCheck.checked === 1)) {
            hoverMenuCheck.unbind().click();
          }

          if ((pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 1)) {
            boxedLayoutCheck.unbind().click();
          }

          $(".theme-color").attr("href", 'assets/css/themes/green.css');

          sidebarAndContentHeight();
        },
        str = logoBox.text(),
        smTxt = (str.slice(0, 1)),
        collapseSidebar = () => {
          body.toggleClass("small-sidebar");
          logoVersion.toggle();
          logoBox.html(logoBox.text() === smTxt ? str : smTxt);
          sidebarAndContentHeight();
        },
        fixedHeader = () => {
          if ((body.hasClass('page-horizontal-bar')) && (body.hasClass('page-sidebar-fixed')) && (body.hasClass('page-header-fixed'))) {
            fixedSidebarCheck.unbind().click();
            alert("Static header isn't compatible with fixed horizontal nav mode. Modern will set static mode on horizontal nav.");
          }
          body.toggleClass('page-header-fixed');
          sidebarAndContentHeight();
        },
        fixedSidebar = () => {
          if ((body.hasClass('page-horizontal-bar')) && (!body.hasClass('page-sidebar-fixed')) && (!body.hasClass('page-header-fixed'))) {
            fixedHeaderCheck.unbind().click();
            alert("Fixed horizontal nav isn't compatible with static header mode. Modern will set fixed mode on header.");
          }
          if ((body.hasClass('hover-menu')) && (!body.hasClass('page-sidebar-fixed'))) {
            hoverMenuCheck.unbind().click();
            alert("Fixed sidebar isn't compatible with hover menu mode. Modern will set accordion mode on menu.");
          }
          body.toggleClass('page-sidebar-fixed');
          sidebarAndContentHeight();
        },
        horizontalBar = () => {
          sidebar.toggleClass('horizontal-bar');
          sidebar.toggleClass('page-sidebar');
          body.toggleClass('page-horizontal-bar');
          if ((body.hasClass('page-sidebar-fixed')) && (!body.hasClass('page-header-fixed'))) {
            fixedHeaderCheck.unbind().click();
            alert("Static header isn't compatible with fixed horizontal nav mode. Modern will set static mode on horizontal nav.");
          }
          sidebarAndContentHeight();
        },
        boxedLayout = () => {
          pageContent.toggleClass('container');
          sidebarAndContentHeight();
        },
        compactMenu = () => {
          body.toggleClass('compact-menu');
          sidebarAndContentHeight();
        },
        hoverMenu = () => {
          if ((!body.hasClass('hover-menu')) && (body.hasClass('page-sidebar-fixed'))) {
            fixedSidebarCheck.unbind().click();
            alert("Fixed sidebar isn't compatible with hover menu mode. Modern will set static mode on sidebar.");
          }
          body.toggleClass('hover-menu');
          sidebarAndContentHeight();
        };


    // Logo text on Collapsed Sidebar
    $('.small-sidebar .navbar .logo-box a span').html(logoBox.text() === smTxt ? str : smTxt);


    if (!themeSettings.length) {
      $('.sidebar-toggle').unbind().click(() => {
        collapseSidebar();
      });
    }

    if (themeSettings.length) {
      fixedHeaderCheck.onchange = () => {
        fixedHeader();
      };

      fixedSidebarCheck.onchange = () => {
        fixedSidebar();
      };

      horizontalBarCheck.onchange = () => {
        horizontalBar();
      };

      toggleSidebarCheck.onchange = () => {
        collapseSidebar();
      };

      compactMenuCheck.onchange = () => {
        compactMenu();
      };

      hoverMenuCheck.onchange = () => {
        hoverMenu();
      };

      boxedLayoutCheck.onchange = () => {
        boxedLayout();
      };


      // Sidebar Toggle
      $('.sidebar-toggle').unbind().click(() => {
        toggleSidebarCheck.click();
      });

      // Reset options
      $('.reset-options').unbind().click(() => {
        defaultOptions();
      });

      // Color changer
      $(".colorbox").unbind().click(() => {
        let color = $(this).attr('data-css');
        $(".theme-color").attr('href', 'assets/css/themes/' + color + '.css');
        return false;
      });

      // Fixed Sidebar Bug
      if (!(body.hasClass('page-sidebar-fixed')) && (fixedSidebarCheck.checked === 1)) {
        body.addClass('page-sidebar-fixed');
      }

      if ((body.hasClass('page-sidebar-fixed')) && (fixedSidebarCheck.checked === 0)) {
        $('.fixed-sidebar-check').prop('checked', true);
      }

      // Fixed Header Bug
      if (!(body.hasClass('page-header-fixed')) && (fixedHeaderCheck.checked === 1)) {
        body.addClass('page-header-fixed');
      }

      if ((body.hasClass('page-header-fixed')) && (fixedHeaderCheck.checked === 0)) {
        $('.fixed-header-check').prop('checked', true);
      }

      // horizontal bar Bug
      if (!(body.hasClass('page-horizontal-bar')) && (horizontalBarCheck.checked === 1)) {
        body.addClass('page-horizontal-bar');
        sidebar.addClass('horizontal-bar');
        sidebar.removeClass('page-sidebar');
      }

      if ((body.hasClass('page-horizontal-bar')) && (horizontalBarCheck.checked === 0)) {
        $('.horizontal-bar-check').prop('checked', true);
      }

      // Toggle Sidebar Bug
      if (!(body.hasClass('small-sidebar')) && (toggleSidebarCheck.checked === 1)) {
        body.addClass('small-sidebar');
      }

      if ((body.hasClass('small-sidebar')) && (toggleSidebarCheck.checked === 0)) {
        $('.horizontal-bar-check').prop('checked', true);
      }

      // Boxed Layout Bug
      if (!(pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 1)) {
        $('.toggle-sidebar-check').addClass('container');
      }

      if ((pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 0)) {
        $('.boxed-layout-check').prop('checked', true);
      }

      // Boxed Layout Bug
      if (!(pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 1)) {
        $('.toggle-sidebar-check').addClass('container');
      }

      if ((pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 0)) {
        $('.boxed-layout-check').prop('checked', true);
      }

      // Boxed Layout Bug
      if (!(pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 1)) {
        $('.toggle-sidebar-check').addClass('container');
      }

      if ((pageContent.hasClass('container')) && (boxedLayoutCheck.checked === 0)) {
        $('.boxed-layout-check').prop('checked', true);
      }
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_LOGGER",
      "UPDATE_SIGNED_IN",
      "UPDATE_COGNITO_USER",
      "UPDATE_LOGGED_IN_USER",
      "UPDATE_SETTINGS",
      "UPDATE_TITLES",
      "UPDATE_BRANCHES",
      "UPDATE_USERS",
      "UPDATE_STATUSES",
      "UPDATE_PRODUCTS",
      "UPDATE_CAMPAIGNS",
      "UPDATE_ALL_CAMPAIGNS",
      "UPDATE_ENABLED_SOURCES",
      "UPDATE_FORMS",
      "UPDATE_DAILY_FORMS",
      "UPDATE_MTD_FORMS",
      "UPDATE_REFRESH_MTD_FORMS",
      "UPDATE_LEAD_FEEDBACKS",
      "UPDATE_LEADS",
      "UPDATE_ALL_LEADS",
      "UPDATE_SEARCH_FORM_TERM",
      "UPDATE_UPDATE_SUBSCRIPTION_FORM",
      "UPDATE_DELETE_SUBSCRIPTION_FORM",
      "UPDATE_ACTIVE_DASHBOARD_BRANCH_ID"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    async updateUser() {
      this.UPDATE_FORMS([]);
      this.UPDATE_LEADS([]);
      let user = null;
      let attributes = null;
      try {
        user = await Auth.currentAuthenticatedUser();
        attributes = await Auth.userAttributes(user);
        _.forEach(attributes, (attribute) => {
          user.attributes[attribute.Name] = attribute.Value;
        });
      } catch (e) {
        this.unsubscribePushSubscriptions();
        this.unsubscribeLists();
        this.updatePinpointEndpoint(false);
        Auth.signOut()
            .then(() => {
              this.getLogger.info("signout success");
              return AmplifyEventBus.$emit("authState", "signedOut");
            })
            .catch(e => this.setMessage('v-notify-error', "Error!", e));
        this.UPDATE_SIGNED_IN(false);
        $('body').addClass('loaded');
      }

      let isTimeSynced;
      let currentEpoch = _.parseInt(moment().format('X'));
      try {
        const {epoch} = await API.get("SalesVoltPublic", "/time", {});
        isTimeSynced = currentEpoch >= (epoch - 300) && currentEpoch <= (epoch + 300);
      } catch (e) {
        isTimeSynced = true;
      }
      if (!isTimeSynced) {
        this.unsubscribePushSubscriptions();
        this.setMessage('v-notify-error', "Error!", "Incorrect system time detected! Please check your time settings.");
        Auth.signOut()
            .then(() => {
              if (user && user !== '') {
                this.$router.push({name: 'home'})
                    .catch(e => {
                      console.error(e);
                      window.location.reload();
                    });
              }
              this.getLogger.info("signout success");
              return AmplifyEventBus.$emit("authState", "signedOut");
            })
            .catch(e => this.setMessage('v-notify-error', "Error!", e));
      }

      if (user && user !== '') {
        try {
          this.unsubscribeLists();

          let loggedInUser = await this.fetchUserById(user.attributes.sub);

          if (!loggedInUser || loggedInUser === '') {
            this.unsubscribePushSubscriptions();
            if (this.$route.name !== 'profile') {
              await this.$router.push({name: 'profile'});
            }
          } else {
            if (!loggedInUser.isEnabled) {
              this.unsubscribePushSubscriptions();
              this.setMessage('v-notify-error', "Error!", "Account is disabled!");
              Auth.signOut()
                  .then(() => {
                    this.$router.push({name: 'home'})
                        .catch(e => {
                          console.error(e);
                          window.location.reload();
                        });
                    this.getLogger.info("signout success");
                    return AmplifyEventBus.$emit("authState", "signedOut");
                  })
                  .catch(e => this.setMessage('v-notify-error', "Error!", e));
            } else {
              if (!loggedInUser.birthDate || loggedInUser.birthDate === '') {
                this.setMessage('v-notify-error', "Final Warning!", 'Missing information on profile page. If not completed, your account will be disabled tomorrow!', 0);
              }
              this.updateLastLogin(loggedInUser.id);
              this.UPDATE_LOGGED_IN_USER(loggedInUser);
              if (loggedInUser.jobTitle && loggedInUser.jobTitle !== '') {
                await this.fetchPickLists();
              }
              await this.checkPushSubscriptionString();
              this.UPDATE_FORMS([]);
              this.fetchForms();
              this.fetchAllLeads();
              // if (_.result(_.find(this.getTitles, (title) => {return title.id === loggedInUser.jobTitle}), 'access') === 'User') {
              // 	this.fetchLeads();
              // }
            }
          }

          this.UPDATE_COGNITO_USER(user);
          this.updatePinpointEndpoint(true);
          this.UPDATE_SIGNED_IN(true);
          $('body').addClass('loaded');
        } catch (e) {
          this.unsubscribePushSubscriptions();
          this.unsubscribeLists();
          this.updatePinpointEndpoint(false);
          Auth.signOut()
              .then(() => {
                this.getLogger.info("signout success");
                return AmplifyEventBus.$emit("authState", "signedOut");
              })
              .catch(e => this.setMessage('v-notify-error', "Error!", e));
          this.UPDATE_SIGNED_IN(false);
          $('body').addClass('loaded');
        }
      } else {
        this.unsubscribePushSubscriptions();
        this.unsubscribeLists();
        this.updatePinpointEndpoint(false);
        Auth.signOut()
            .then(() => {
              this.getLogger.info("signout success");
              return AmplifyEventBus.$emit("authState", "signedOut");
            })
            .catch(e => this.setMessage('v-notify-error', "Error!", e));
        this.UPDATE_SIGNED_IN(false);
        $('body').addClass('loaded');
      }
    },
    async updateLastLogin(userId) {
      const currentEpoch = moment().format('X');
      await API.graphql(graphqlOperation(mutations.updateUser, {
        input: {
          id: userId,
          lastOnline: moment(currentEpoch, 'X').toISOString(),
          lastOnlineEpoch: currentEpoch
        }
      }));
    },
    async updatePinpointEndpoint(enable) {
      try {
        if (enable) {
          Analytics.enable();
          await Analytics.updateEndpoint({
            address: this.getLoggedInUser.email, // Or phone_number
            channelType: 'EMAIL', // Or 'SMS'
            userId: this.getLoggedInUser.id,
            userAttributes: _.mapValues(this.getLoggedInUser, (value) => [`${value}`]),
            optOut: 'NONE',
          });
          await Analytics.startSession();
          // Analytics.autoTrack('session', {
          // 	enable: true
          // });
          Analytics.autoTrack('pageView', {
            // REQUIRED, turn on/off the auto tracking
            enable: true
          });
          Analytics.autoTrack('event', {
            enable: true,
            events: ['click'],
            selectorPrefix: 'data-amplify-analytics-',
          });
        } else {
          Analytics.disable();
        }
      } catch (err) {
        Analytics.disable();
      }
    },
    async fetchPickLists() {
      const {
        data: {listSettings}
      } = await API.graphql(graphqlOperation(queries.listSettings, {limit: 99})); //Limit is 10 by default so must override

      this.UPDATE_SETTINGS(listSettings.items);

      this.createSettingSubscription = API.graphql(graphqlOperation(subscriptions.onCreateSetting
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getSettings);
          oldList.push(update.value.data.onCreateSetting);
          this.UPDATE_SETTINGS(oldList);
        }
      });

      this.updateSettingSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateSetting
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getSettings);
          let settingIndex = _.findIndex(oldList, (setting) => {
            return setting.id === update.value.data.onUpdateSetting.id;
          });
          if (settingIndex !== -1) {
            oldList[settingIndex] = update.value.data.onUpdateSetting;
            this.UPDATE_SETTINGS(oldList);
          }
        }
      });

      const {
        data: {listTitles}
      } = await API.graphql(graphqlOperation(queries.listTitles, {limit: 999})); //Limit is 10 by default so must override

      this.UPDATE_TITLES(listTitles.items);

      this.createTitleSubscription = API.graphql(graphqlOperation(subscriptions.onCreateTitle
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getTitles);
          oldList.push(update.value.data.onCreateTitle);
          this.UPDATE_TITLES(oldList);
        }
      });

      this.updateTitleSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTitle
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getTitles);
          let jobTitleIndex = _.findIndex(oldList, (title) => {
            return title.id === update.value.data.onUpdateTitle.id;
          });
          if (jobTitleIndex !== -1) {
            oldList[jobTitleIndex] = update.value.data.onUpdateTitle;
            this.UPDATE_TITLES(oldList);
          }
        }
      });

      const {
        data: {listBranchs}
      } = await API.graphql(graphqlOperation(queries.listBranchs, {limit: 999})); //Limit is 10 by default so must override

      this.UPDATE_BRANCHES(listBranchs.items);

      this.createBranchSubscription = API.graphql(graphqlOperation(subscriptions.onCreateBranch
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getBranches);
          oldList.push(update.value.data.onCreateBranch);
          this.UPDATE_BRANCHES(oldList);
        }
      });

      this.updateBranchSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateBranch
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getBranches);
          let branchIndex = _.findIndex(oldList, (branch) => {
            return branch.id === update.value.data.onUpdateBranch.id;
          });
          if (branchIndex !== -1) {
            oldList[branchIndex] = update.value.data.onUpdateBranch;
            this.UPDATE_BRANCHES(oldList);
          }
        }
      });

      const {
        data: {listStatuss}
      } = await API.graphql(graphqlOperation(queries.listStatuss, {
        filter: {
          isEnabled: {
            eq: true
          }
        },
        limit: 999
      })); //Limit is 10 by default so must override

      this.UPDATE_STATUSES(listStatuss.items);

      this.createStatusSubscription = API.graphql(graphqlOperation(subscriptions.onCreateStatus
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getStatuses);
          oldList.push(update.value.data.onCreateStatus);
          this.UPDATE_STATUSES(oldList);
        }
      });

      this.updateStatusSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateStatus
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getStatuses);
          let statusIndex = _.findIndex(oldList, (status) => {
            return status.id === update.value.data.onUpdateStatus.id;
          });
          if (statusIndex !== -1) {
            oldList[statusIndex] = update.value.data.onUpdateStatus;
            this.UPDATE_STATUSES(oldList);
          }
        }
      });

      const {
        data: {listProducts}
      } = await API.graphql(graphqlOperation(queries.listProducts, {limit: 999})); //Limit is 10 by default so must override

      this.UPDATE_PRODUCTS(listProducts.items);

      this.updateProductSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateProduct
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getProducts);
          let productIndex = _.findIndex(oldList, (product) => {
            return product.id === update.value.data.onUpdateProduct.id;
          });
          if (productIndex !== -1) {
            oldList[productIndex] = update.value.data.onUpdateProduct;
            this.UPDATE_PRODUCTS(oldList);
          }
        }
      });

      const {
        data: {listLeadFeedbacks}
      } = await API.graphql(graphqlOperation(queries.listLeadFeedbacks, {limit: 999})); //Limit is 10 by default so must override

      this.UPDATE_LEAD_FEEDBACKS(listLeadFeedbacks.items);

      this.createLeadFeedbackSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLeadFeedback
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getLeadFeedbacks);
          oldList.push(update.value.data.onCreateLeadFeedback);
          this.UPDATE_LEAD_FEEDBACKS(oldList);
        }
      });

      this.updateLeadFeedbackSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateLeadFeedback
      )).subscribe({
        next: (update) => {
          let oldList = _.cloneDeep(this.getLeadFeedbacks);
          let feedbackIndex = _.findIndex(oldList, (feedback) => {
            return feedback.id === update.value.data.onUpdateLeadFeedback.id;
          });
          if (feedbackIndex !== -1) {
            oldList[feedbackIndex] = update.value.data.onUpdateLeadFeedback;
            this.UPDATE_LEAD_FEEDBACKS(oldList);
          }
        }
      });

      const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
        return title.id === this.getLoggedInUser.jobTitle
      }), 'access');
      this.UPDATE_USERS([]);
      await this.fetchUsersList(currentUserJobTitle);
      if (currentUserJobTitle !== 'Owner') {
        let supervisor = await this.fetchUserById(this.getLoggedInUser.supervisor);
        if (supervisor && supervisor !== '' && supervisor.supervisor && supervisor.supervisor !== '') {
          let manager = await this.fetchUserById(supervisor.supervisor);
          if (manager && manager !== '') {
            this.UPDATE_USERS(_.uniqBy(_.concat(this.getUsers, [supervisor, manager])));
          }
        }
      }
      this.UPDATE_USERS(_.uniqBy(_.concat(this.getUsers, [this.getLoggedInUser]), 'id'));

      this.updateUserSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateUser
      )).subscribe({
        next: (update) => {
          let updatedUser = update.value.data.onUpdateUser;
          if (updatedUser.id === this.getLoggedInUser.id) {
            if (!updatedUser.isEnabled) {
              this.setMessage('v-notify-error', "Error!", "Account is disabled!");
              Auth.signOut()
                  .then(() => {
                    this.$router.push({name: 'home'})
                        .catch(e => {
                          console.error(e);
                          window.location.reload();
                        });
                    this.getLogger.info("signout success");
                    return AmplifyEventBus.$emit("authState", "signedOut");
                  })
                  .catch(e => this.setMessage('v-notify-error', "Error!", e));
            } else {
              if (updatedUser.jobTitle !== this.getLoggedInUser.jobTitle ||
                  updatedUser.supervisor !== this.getLoggedInUser.supervisor ||
                  !_.isEqual(_.sortBy(updatedUser.branchId), _.sortBy(this.getLoggedInUser.branchId)) ||
                  !_.isEqual(_.sortBy(updatedUser.products), _.sortBy(this.getLoggedInUser.products))) {
                this.setMessage('alert', 'Alert!', "An administrator changed your account settings. You will now be re-directed back to the login page.")
                    .then(() => {
                      Auth.signOut()
                          .then(() => {
                            this.$router.push({name: 'home'})
                                .catch(e => {
                                  console.error(e);
                                  window.location.reload();
                                });
                            this.getLogger.info("signout success");
                            return AmplifyEventBus.$emit("authState", "signedOut");
                          })
                          .catch(e => this.setMessage('v-notify-error', "Error!", e));
                    });
              } else {
                this.UPDATE_LOGGED_IN_USER(updatedUser);
              }
            }
          } else if (updatedUser.isEnabled) {
            let oldUsers = _.cloneDeep(this.getUsers);
            let userIndex = _.findIndex(oldUsers, (user) => {
              return user.id === updatedUser.id;
            });
            if (userIndex !== -1) {
              oldUsers[userIndex] = updatedUser;
            } else {
              if (!_.includes(_.map(_.filter(this.getTitles, (title) => {
                return _.includes(['User', 'Supervisor', 'BackOffice', 'Manager'], title.access);
              }), 'id'), updatedUser.jobTitle)) {
                oldUsers.push(updatedUser);
              } else if (currentUserJobTitle === 'Manager') {
                if (_.includes(_.map(this.getUsers, 'id'), updatedUser.supervisor)) {
                  oldUsers.push(updatedUser);
                }
              } else if (currentUserJobTitle === 'Supervisor') {
                if (updatedUser.supervisor === this.getLoggedInUser.id) {
                  oldUsers.push(updatedUser);
                }
              } else if (currentUserJobTitle === 'BackOffice') {
                if (_.includes(updatedUser.branchId, this.getLoggedInUser.branchId[0])) {
                  oldUsers.push(updatedUser);
                }
              } else if (currentUserJobTitle !== 'User') {
                oldUsers.push(updatedUser);
              }
            }
            this.UPDATE_USERS(oldUsers);
          }
        }
      });

      if (currentUserJobTitle !== 'User') {
        await this.fetchCampaigns();

        this.createCampaignSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLeadPool
        )).subscribe({
          next: (update) => {
            let createdCampaign = update.value.data.onCreateLeadPool;

            let oldList = _.cloneDeep(this.getCampaigns);
            if (_.includes(createdCampaign.members, this.getLoggedInUser.username)) {
              oldList.push(createdCampaign);
              this.UPDATE_CAMPAIGNS(oldList);
            }

            let oldAllList = _.cloneDeep(this.getAllCampaigns);
            oldAllList.push(createdCampaign);
            this.UPDATE_ALL_CAMPAIGNS(oldAllList);
          }
        });
        this.updateCampaignSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateLeadPool
        )).subscribe({
          next: (update) => {
            let updatedCampaign = update.value.data.onUpdateLeadPool;

            let oldList = _.cloneDeep(this.getCampaigns);
            let campaignIndex = _.findIndex(oldList, (campaign) => {
              return campaign.id === updatedCampaign.id;
            });
            if (campaignIndex !== -1) {
              if (_.includes(updatedCampaign.members, this.getLoggedInUser.username)) {
                oldList[campaignIndex] = updatedCampaign;
              } else {
                _.remove(oldList, (campaign) => {
                  return campaign.id === updatedCampaign.id;
                });
              }
              this.UPDATE_CAMPAIGNS(oldList);
            } else {
              if (_.includes(updatedCampaign.members, this.getLoggedInUser.username)) {
                oldList.push(updatedCampaign);
                this.UPDATE_CAMPAIGNS(oldList);
              }
            }

            let oldAllList = _.cloneDeep(this.getAllCampaigns);
            let allCampaignIndex = _.findIndex(oldAllList, (campaign) => {
              return campaign.id === updatedCampaign.id;
            });
            if (allCampaignIndex !== -1) {
              oldAllList[allCampaignIndex] = updatedCampaign;
              this.UPDATE_ALL_CAMPAIGNS(oldAllList);
            }
          }
        });
        this.deleteCampaignSubscription = API.graphql(graphqlOperation(subscriptions.onDeleteLeadPool
        )).subscribe({
          next: (update) => {
            let deletedCampaign = update.value.data.onDeleteLeadPool;

            let oldList = _.cloneDeep(this.getCampaigns);
            _.remove(oldList, (campaign) => {
              return campaign.id === deletedCampaign.id;
            });
            this.UPDATE_CAMPAIGNS(oldList);

            let oldListAll = _.cloneDeep(this.getAllCampaigns);
            _.remove(oldListAll, (campaign) => {
              return campaign.id === deletedCampaign.id;
            });
            this.UPDATE_ALL_CAMPAIGNS(oldListAll);
          }
        });
      } else {
        this.fetchAssignedLeads();
      }
    },
    async fetchUsersList(currentUserJobTitle, nextPage = null) {
      try {
        let filter = {
          isEnabled: {
            eq: true
          }
        };
        if (_.includes(['Manager', 'Supervisor'], currentUserJobTitle)) {
          await this.fetchUsersListBySupervisor(this.getLoggedInUser.id, filter);
          let supervisorTitles = _.map(_.filter(this.getTitles, (title) => {
            return _.includes(['Manager', 'Supervisor'], title.access);
          }), 'id');
          let supervisors = _.filter(_.cloneDeep(this.getUsers), (user) => {
            return _.includes(supervisorTitles, user.jobTitle);
          });
          if (supervisors && supervisors.length > 0) {
            let promises = [];
            for (let userIdx = 0; userIdx < supervisors.length; userIdx++) {
              if (supervisors[userIdx].id !== this.getLoggedInUser.id) {
                promises.push(this.fetchUsersListBySupervisor(supervisors[userIdx].id, filter));
              }
            }
            await Promise.all(promises);
          }
        } else if (currentUserJobTitle === 'BackOffice') {
          let jobTitles = _.filter(this.getTitles, (title) => {
            return _.includes(['User', 'Supervisor', 'Manager'], title.access);
          });
          let promises = [];
          for (let titleIdx = 0; titleIdx < jobTitles.length; titleIdx++) {
            promises.push(this.fetchUsersListByJobTitle(jobTitles[titleIdx].id, filter));
          }
          await Promise.all(promises);
        }
        if (_.includes(['User', 'Supervisor', 'Manager', 'BackOffice'], currentUserJobTitle)) {
          let jobTitles = _.filter(this.getTitles, (title) => {
            return !_.includes(['User', 'Supervisor', 'Manager'], title.access);
          });
          let promises = [];
          for (let titleIdx = 0; titleIdx < jobTitles.length; titleIdx++) {
            promises.push(this.fetchUsersListByJobTitle(jobTitles[titleIdx].id, filter));
          }
          await Promise.all(promises);
        } else {
          const {
            data: {
              listUsers: {items, nextToken}
            }
          } = await API.graphql(graphqlOperation(customQueries.publicListUsers, {
            limit: 999,
            filter: filter,
            nextToken: nextPage
          }));
          nextPage = nextToken;
          this.UPDATE_USERS(_.uniqBy(_.concat(this.getUsers, items), 'id'));
        }
        if (nextPage) {
          await this.fetchUsersList(currentUserJobTitle, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchUserById(userId) {
      try {
        const {
          data: {getUser}
        } = await API.graphql(graphqlOperation(queries.getUser, {id: userId}));
        return getUser;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async fetchUsersListBySupervisor(supervisor, filter = null, nextPage = null) {
      try {
        const {
          data: {
            usersBySupervisor: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(customQueries.publicUsersListBySupervisor, {
          supervisor: supervisor,
          limit: 999,
          filter: filter,
          nextToken: nextPage
        }));
        nextPage = nextToken;
        this.UPDATE_USERS(_.uniqBy(_.concat(this.getUsers, items), 'id'));
        if (nextPage) {
          await this.fetchUsersListBySupervisor(supervisor, filter, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchUsersListByJobTitle(jobTitle, filter = null, nextPage = null) {
      try {
        const {
          data: {
            usersByJobTitle: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(customQueries.publicUsersListByJobTitle, {
          jobTitle: jobTitle,
          limit: 999,
          filter: filter,
          nextToken: nextPage
        }));
        nextPage = nextToken;
        this.UPDATE_USERS(_.uniqBy(_.concat(this.getUsers, items), 'id'));
        if (nextPage) {
          await this.fetchUsersListByJobTitle(jobTitle, filter, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCampaigns(nextPage = null) {
      try {
        const {
          data: {
            listLeadPools: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(queries.listLeadPools, {
          limit: 999,
          nextToken: nextPage
        }));
        this.UPDATE_ALL_CAMPAIGNS(_.uniqBy(_.concat(this.getAllCampaigns, items)));
        this.UPDATE_CAMPAIGNS(_.uniqBy(_.concat(this.getCampaigns, _.filter(items, (campaign) => {
          return _.includes(campaign.members, this.getLoggedInUser.username);
        })), 'id'));

        nextPage = nextToken;
        if (nextPage) {
          await this.fetchCampaigns(nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchEnabledSources(nextPage = null) {
      try {
        const {
          data: {
            sourcesByEnabled: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(queries.sourcesByEnabled, {
          enabled: "true",
          limit: 999,
          nextToken: nextPage
        }));
        nextPage = nextToken;
        this.UPDATE_ENABLED_SOURCES(_.uniqBy(_.concat(this.getEnabledSources, items)));
        if (nextPage) {
          this.fetchEnabledSources(nextPage);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchAssignedLeads(nextPage = null) {
      try {
        const {
          data: {
            leadsByAssignedToVersion: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(queries.leadsByAssignedToVersion, {
          assignedTo: this.getLoggedInUser.username,
          version: {
            ge: 1
          },
          limit: 999,
          nextToken: nextPage
        }));
        this.UPDATE_LEADS(_.uniqBy(_.concat(this.getLeads, items), 'id'));

        nextPage = nextToken;
        if (nextPage) {
          await this.fetchAssignedLeads(nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAllLeads() {
      const jobType = _.result(_.find(this.getTitles, (title) => {
        return title.id === this.getLoggedInUser.jobTitle
      }), 'access');
      if (!_.includes(["Manager", "Supervisor", "User"], jobType)) {
        return;
      }

      if (jobType === 'Supervisor') {
        this.createLeadSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLogSupervisor, {
              action: 'LEAD',
              supervisor: this.getLoggedInUser.username
            }
        )).subscribe({
          next: (update) => {
            let newLead = update.value.data.onCreateLogSupervisor;
            let oldLeads = _.cloneDeep(this.getAllLeads);
            oldLeads.push(newLead);
            this.UPDATE_ALL_LEADS(_.uniqBy(oldLeads, 'id'));
          }
        });
      } else if (jobType === 'Manager') {
        this.createLeadSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLogBranchManager, {
              action: 'LEAD',
              branchManager: this.getLoggedInUser.username
            }
        )).subscribe({
          next: (update) => {
            let newLead = update.value.data.onCreateLogBranchManager;
            let oldLeads = _.cloneDeep(this.getAllLeads);
            oldLeads.push(newLead);
            this.UPDATE_ALL_LEADS(_.uniqBy(oldLeads, 'id'));
          }
        });
      }

      this.updateSourceSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateLeadSource
      )).subscribe({
        next: (update) => {
          let updatedSource = update.value.data.onUpdateLeadSource;
          let oldList = _.cloneDeep(this.getEnabledSources);
          let sourceIndex = _.findIndex(oldList, (source) => {
            return source.id === updatedSource.id;
          });
          if (sourceIndex !== -1) {
            if (updatedSource.enabled && updatedSource.enabled === 'true') {
              oldList[sourceIndex] = updatedSource;
            } else {
              _.remove(oldList, (source) => {
                return source.id === updatedSource.id;
              });
            }
          } else {
            if (updatedSource.enabled && updatedSource.enabled === 'true') {
              oldList.push(updatedSource);
            }
          }
          this.UPDATE_ENABLED_SOURCES(oldList);
        }
      });
      this.fetchEnabledSources();
      this.fetchLeadsByAction(jobType);
    },
    async fetchLeadsByAction(jobType, nextPage = null) {
      try {
        let logFilter = {
          action: 'LEAD'
        };
        let query = customQueries.countLeadsByActionWithEpochMinimal;
        if (jobType === 'User') {
          logFilter = {
            owner: this.getLoggedInUser.username
          };
          query = customQueries.countLeadsByOwnerWithEpochMinimal;
        } else if (jobType === 'Supervisor') {
          logFilter = {
            supervisor: this.getLoggedInUser.username
          };
          query = customQueries.countLeadsBySupervisorWithEpochMinimal;
        } else if (jobType === 'Manager') {
          logFilter = {
            branchManager: this.getLoggedInUser.username
          };
          query = customQueries.countLeadsByBranchManagerWithEpochMinimal;
        }
        const {data} = await API.graphql(graphqlOperation(query, {
          ...logFilter,
          actionEpoch: {
            ge: moment().startOf('day').format('X')
          },
          filter: {
            action: {
              eq: 'LEAD'
            }
          },
          limit: 999,
          nextToken: nextPage
        }));
        if (jobType === 'User') {
          this.UPDATE_ALL_LEADS(_.concat(this.getAllLeads, data.logsByOwnerWithEpoch.items));
          nextPage = data.logsByOwnerWithEpoch.nextToken;
          if (nextPage) {
            this.fetchLeadsByAction(jobType, nextPage);
          }
        } else if (jobType === 'Supervisor') {
          this.UPDATE_ALL_LEADS(_.concat(this.getAllLeads, data.logsBySupervisorWithEpoch.items));
          nextPage = data.logsBySupervisorWithEpoch.nextToken;
          if (nextPage) {
            this.fetchLeadsByAction(jobType, nextPage);
          }
        } else if (jobType === 'Manager') {
          this.UPDATE_ALL_LEADS(_.concat(this.getAllLeads, data.logsByBranchManagerWithEpoch.items));
          nextPage = data.logsByBranchManagerWithEpoch.nextToken;
          if (nextPage) {
            this.fetchLeadsByAction(jobType, nextPage);
          }
        } else {
          this.UPDATE_ALL_LEADS(_.concat(this.getAllLeads, data.formsByActionWithEpoch.items));
          nextPage = data.formsByActionWithEpoch.nextToken;
          if (nextPage) {
            this.fetchLeadsByAction(jobType, nextPage);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    fetchForms() {
      const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
        return title.id === this.getLoggedInUser.jobTitle
      }), 'access');
      let filter = null;
      if (!this.getActiveDashboardBranchId) {
        this.UPDATE_ACTIVE_DASHBOARD_BRANCH_ID(this.getLoggedInUser.branchId[0]);
      }
      let branches = currentUserJobTitle === 'Owner' ? [this.getActiveDashboardBranchId] : this.getLoggedInUser.branchId;

      _.forEach(branches, (branchId) => {
        if (currentUserJobTitle === 'User') {
          filter = {
            owner: {
              eq: this.getLoggedInUser.username
            }
          };
        } else if (currentUserJobTitle === 'Supervisor') {
          filter = {
            supervisor: {
              eq: this.getLoggedInUser.username
            }
          };
        } else if (currentUserJobTitle === 'Manager') {
          filter = {
            branchManager: {
              eq: this.getLoggedInUser.username
            }
          };
        } else {
          filter = {
            branchId: {
              eq: branchId
            }
          };
        }
        if (this.getLoggedInUser.products && this.getLoggedInUser.products.length > 0) {
          filter.product = {
            eq: this.getLoggedInUser.products[0]
          };
        }
        _(this.getStatuses)
            .filter((status) => {
              return _.includes(status.alltime, currentUserJobTitle);
            })
            .forEach((status) => {
              if (currentUserJobTitle === 'User') {
                this.fetchFormsByStatusOwner(status.id, _.includes(status.allFields, currentUserJobTitle), filter, null);
              } else {
                this.fetchFormsByStatus(status.id, _.includes(status.allFields, currentUserJobTitle), filter, null);
              }
            });
        _(this.getStatuses)
            .filter((status) => {
              return _.includes(status.daily, currentUserJobTitle);
            })
            .forEach((status) => {
              this.fetchFormsByStatusDaily(status.id, moment().startOf('day').format('X'), _.includes(status.allFields, currentUserJobTitle), filter, currentUserJobTitle, null);
            });
        if (!_.includes(["Manager, Supervisor", "Owner"], currentUserJobTitle)) {
          _(this.getStatuses)
              .filter((status) => {
                return _.includes(status.currentMonth, currentUserJobTitle);
              })
              .forEach((status) => {
                this.fetchFormsByStatusMtd(status.id, moment().startOf('month').format('X'), filter, currentUserJobTitle, null);
              });
        }
      });

      if (currentUserJobTitle !== 'User') {
        if (!this.createFormSubscription) {
          this.createFormSubscription = API.graphql(graphqlOperation(subscriptions.onCreateForm
          )).subscribe({
            next: (update) => {
              this.addNewForm(update.value.data.onCreateForm, currentUserJobTitle);
            }
          });
        }
      }
      if (!this.deleteFormSubscription) {
        this.deleteFormSubscription = API.graphql(graphqlOperation(subscriptions.onDeleteForm
        )).subscribe({
          next: (update) => {
            let oldForms = _.cloneDeep(this.getForms);
            let deleteForm = _.remove(oldForms, (form) => {
              return form.id === update.value.data.onDeleteForm.id;
            });
            this.UPDATE_FORMS(oldForms);
            if (deleteForm.length > 0) {
              this.UPDATE_DELETE_SUBSCRIPTION_FORM(deleteForm[0]);
            }
          }
        });
      }
      if (!this.updateFormSubscription) {
        this.updateFormSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateForm
        )).subscribe({
          next: (update) => {
            let form = update.value.data.onUpdateForm;
            if (this.getLoggedInUser.username !== form.updatedBy) {
              let formStatus = _.find(this.getStatuses, {id: form.status});
              let oldForms = _.cloneDeep(this.getForms);
              let formIndex = _.findIndex(oldForms, (oldForm) => {
                return oldForm.id === form.id;
              });
              if (formIndex !== -1) {
                oldForms[formIndex] = form;
                this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
                this.UPDATE_UPDATE_SUBSCRIPTION_FORM(form);
                if (currentUserJobTitle === 'User' || (formStatus && _.includes(formStatus.editable, currentUserJobTitle))) {
                  //this.setMessage('v-notify-info', "Notification", this.prettyPrint(form.updatedBy, 'User') + " updated one of your forms!");
                }
              } else if (formStatus && (_.includes(formStatus.daily, currentUserJobTitle) || _.includes(formStatus.alltime, currentUserJobTitle))) {
                this.addNewForm(form, currentUserJobTitle);
              }
            }
          }
        });
      }
    },
    addNewForm: function (form, currentUserJobTitle) {
      let currentStatus = _.find(this.getStatuses, (status) => {
        return status.id === form.status;
      });
      if (_.includes(currentStatus.daily, currentUserJobTitle) || _.includes(currentStatus.alltime, currentUserJobTitle)) {
        if (currentUserJobTitle === 'Owner') {
          if (this.getActiveDashboardBranchId === form.branchId) {
            let oldForms = _.cloneDeep(this.getForms);
            oldForms.push(form);
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          }
        } else if (currentUserJobTitle === 'Partner' || currentUserJobTitle === 'BackOffice') {
          if (_.includes(this.getLoggedInUser.branchId, form.branchId) &&
              (!this.getLoggedInUser.products || this.getLoggedInUser.products === '' || this.getLoggedInUser.products.length === 0 || _.includes(this.getLoggedInUser.products, form.product))) {
            let oldForms = _.cloneDeep(this.getForms);
            oldForms.push(form);
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
            //this.setMessage('v-notify-info', "Notification", this.prettyPrint(form.updatedBy, 'User') + " updated one of your forms!");
          }
        } else if (currentUserJobTitle === 'Supervisor') {
          if (this.getLoggedInUser.username === form.supervisor) {
            let oldForms = _.cloneDeep(this.getForms);
            oldForms.push(form);
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          }
        } else if (currentUserJobTitle === 'Manager') {
          if (this.getLoggedInUser.username === form.branchManager) {
            let oldForms = _.cloneDeep(this.getForms);
            oldForms.push(form);
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          }
        } else if (currentUserJobTitle === 'User') {
          if (this.getLoggedInUser.username === form.owner) {
            let oldForms = _.cloneDeep(this.getForms);
            oldForms.push(form);
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
            if (this.getLoggedInUser.username !== form.updatedBy) {
              //this.setMessage('v-notify-info', "Notification", this.prettyPrint(form.updatedBy, 'User') + " updated one of your forms!");
            }
          }
        } else {
          let oldForms = _.cloneDeep(this.getForms);
          oldForms.push(form);
          this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          //this.setMessage('v-notify-info', "Notification", this.prettyPrint(form.updatedBy, 'User') + " updated one of your forms!");
        }
      }
    },
    async fetchFormsByStatusOwner(status, showAllFields = false, filter = null, nextPage = null) {
      try {
        const {
          data: {
            formsByStatusOwner: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(showAllFields ? queries.formsByStatusOwner : customQueries.countFormsByStatusOwner, {
          status: status,
          owner: {
            eq: filter.owner.eq
          },
          limit: 999,
          nextToken: nextPage
        }));
        this.UPDATE_FORMS(_.uniqBy(_.concat(this.getForms, items), 'id'));

        nextPage = nextToken;
        if (nextPage) {
          this.fetchFormsByStatusOwner(status, showAllFields, filter, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchFormsByStatus(status, showAllFields = false, filter = null, nextPage = null) {
      try {
        const {
          data: {
            formsByStatus: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(showAllFields ? queries.formsByStatus : customQueries.countFormsByStatus, {
          status: status,
          limit: 999,
          filter: filter,
          nextToken: nextPage
        }));
        this.UPDATE_FORMS(_.uniqBy(_.concat(this.getForms, items), 'id'));

        nextPage = nextToken;
        if (nextPage) {
          this.fetchFormsByStatus(status, showAllFields, filter, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchFormsByStatusDaily(status, date, showAllFields, filter = null, jobType = null, nextPage = null) {
      try {
        let logFilter = null;
        if (jobType === 'User') {
          logFilter = {
            formOwner: {
              eq: this.getLoggedInUser.username
            }
          };
        } else if (!_.includes(["Owner", "Partner", "Manager", "Supervisor"], jobType)) {
          logFilter = {
            owner: {
              eq: this.getLoggedInUser.username
            }
          };
        }
        const {
          data: {
            formsByActionWithEpoch: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(
            showAllFields ? customQueries.formsByActionWithEpochAllFields : customQueries.countFormsByActionWithEpoch, {
              action: status,
              actionEpoch: {
                ge: date
              },
              filter: logFilter,
              limit: 999,
              nextToken: nextPage
            }));
        let forms = _.map(items, 'form');
        if (filter) {
          if (filter.branchId) {
            forms = _.filter(forms, {'branchId': filter.branchId.eq});
          } else if (filter.supervisor) {
            forms = _.filter(forms, {'supervisor': filter.supervisor.eq});
          } else if (filter.branchManager) {
            forms = _.filter(forms, {'branchManager': filter.branchManager.eq});
          } else if (filter.owner) {
            forms = _.filter(forms, {'owner': filter.owner.eq})
          }
          if (filter.product) {
            forms = _.filter(forms, {'product': filter.product.eq})
          }
        }
        _.pull(forms, null);
        this.UPDATE_FORMS(_.uniqBy(_.concat(this.getForms, forms), 'id'));
        nextPage = nextToken;
        if (nextPage) {
          this.fetchFormsByStatusDaily(status, date, showAllFields, filter, jobType, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchFormsByStatusMtd(status, date, filter = null, jobType = null, nextPage = null) {
      try {
        let logFilter = null;
        if (jobType === 'User') {
          logFilter = {
            formOwner: {
              eq: this.getLoggedInUser.username
            }
          };
        } else if (!_.includes(["Owner", "Partner", "Manager", "Supervisor", "CentralBackOffice"], jobType)) {
          logFilter = {
            owner: {
              eq: this.getLoggedInUser.username
            }
          };
        }
        const {
          data: {
            formsByActionWithEpoch: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(customQueries.countFormsByActionWithEpochMinimal
            , {
              action: status,
              actionEpoch: {
                ge: date
              },
              filter: logFilter,
              limit: 999,
              nextToken: nextPage
            }));
        let dailyForms = _.map(_.filter(items, (log) => {
          return log.actionEpoch >= moment().startOf('day').format('X') && log.form;
        }), (log) => {
          return {
            logId: log.id,
            id: log.form.id,
            owner: log.form.owner,
            status: log.action
          };
        });
        let mtdForms = _.map(_.filter(items, (log) => {
          return log.form;
        }), (log) => {
          return {
            logId: log.id,
            id: log.form.id,
            owner: log.form.owner,
            status: log.action
          };
        });
        if (filter) {
          if (filter.owner) {
            dailyForms = _.filter(dailyForms, {'owner': filter.owner.eq});
            mtdForms = _.filter(mtdForms, {'owner': filter.owner.eq});
          }
        }
        _.pull(dailyForms, null);
        _.pull(mtdForms, null);
        this.UPDATE_DAILY_FORMS(_.uniqBy(_.concat(this.getDailyForms, dailyForms), (form) => [form.id, form.status].join()));
        this.UPDATE_MTD_FORMS(_.uniqBy(_.concat(this.getMtdForms, mtdForms), (form) => [form.id, form.status].join()));
        nextPage = nextToken;
        if (nextPage) {
          this.fetchFormsByStatusMtd(status, date, filter, jobType, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async checkPushSubscriptionString() {
      if (!navigator.serviceWorker) {
        return;
      }
      let registrations = await navigator.serviceWorker.getRegistrations();
      if (registrations.length < 1) {
        this.unsubscribePushSubscriptions();
        return;
      }
      if (this.getLoggedInUser.pushSubscriptions && this.getLoggedInUser.pushSubscriptions.length > 0) {
        let serviceWorkerRegistration = await navigator.serviceWorker.ready;
        try {
          let options = {
            userVisibleOnly: true,
            applicationServerKey: 'BP8qzHt90VAyEoOziUULbe2BmqbysxNPVg9xfGsjLsKXNEG_YnX6rJ8A7zVqzRWj-36obFN9N61tiCq_H69mMAA'
          };
          let pushSubscription = await serviceWorkerRegistration.pushManager.subscribe(options);
          let currentObject = pushSubscription.toJSON();
          let pushSubscriptionsUpdate = [];
          let pushSubscriptionsOld = _.filter(this.getLoggedInUser.pushSubscriptions, (subscription) => {
            let subscriptionObject = JSON.parse(subscription);
            window.localStorage.setItem('pushSubscription' + subscriptionObject.type, JSON.stringify(currentObject));
            if (currentObject.endpoint !== subscriptionObject.endpoint || currentObject.keys['p256dh'] !== subscriptionObject.keys['p256dh'] || currentObject.keys.auth !== subscriptionObject.keys.auth) {
              let newSubscription = currentObject;
              newSubscription.type = subscriptionObject.type;
              pushSubscriptionsUpdate.push(JSON.stringify(newSubscription));
              return false;
            }
            return true;
          });
          if (pushSubscriptionsUpdate.length > 0) {
            let pushSubscriptions = _.concat(pushSubscriptionsUpdate, pushSubscriptionsOld);
            await API.graphql(graphqlOperation(mutations.updateUser, {
              input: {
                id: this.getLoggedInUser.id,
                pushSubscriptions: pushSubscriptions
              }
            }));
            let updatedUser = _.cloneDeep(this.getLoggedInUser);
            updatedUser.pushSubscriptions = pushSubscriptions;
            this.UPDATE_LOGGED_IN_USER(updatedUser);
          }
        } catch (error) {
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: {
              id: this.getLoggedInUser.id,
              pushSubscriptions: null
            }
          }));
          let updatedUser = _.cloneDeep(this.getLoggedInUser);
          updatedUser.pushSubscriptions = null;
          this.UPDATE_LOGGED_IN_USER(updatedUser);
          this.unsubscribePushSubscriptions();
        }

      } else {
        this.unsubscribePushSubscriptions();
      }
    },
    async unsubscribePushSubscriptions() {
      _.forEach(_.values(pushNotificationCategories), (category) => {
        window.localStorage.removeItem('pushSubscription' + category);
      });
      if (!navigator.serviceWorker) {
        return;
      }
      let registrations = await navigator.serviceWorker.getRegistrations();
      if (registrations.length < 1) {
        return;
      }
      try {
        let serviceWorkerRegistration = await navigator.serviceWorker.ready;
        let pushSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (pushSubscription) {
          await pushSubscription.unsubscribe();
        }
      } catch (error) {
        console.error("Error during fetching service worker", error);
      }
    },
    unsubscribeLists: function () {
      if (this.createSettingSubscription) {
        this.createSettingSubscription.unsubscribe();
      }
      if (this.createTitleSubscription) {
        this.createTitleSubscription.unsubscribe();
      }
      if (this.createStatusSubscription) {
        this.createStatusSubscription.unsubscribe();
      }
      if (this.createBranchSubscription) {
        this.createBranchSubscription.unsubscribe();
      }
      if (this.createUserSubscription) {
        this.createUserSubscription.unsubscribe();
      }
      if (this.updateUserSubscription) {
        this.updateUserSubscription.unsubscribe();
      }
      if (this.updateProductSubscription) {
        this.updateProductSubscription.unsubscribe();
      }
      if (this.updateStatusSubscription) {
        this.updateStatusSubscription.unsubscribe();
      }
      if (this.updateBranchSubscription) {
        this.updateBranchSubscription.unsubscribe();
      }
      if (this.updateTitleSubscription) {
        this.updateTitleSubscription.unsubscribe();
      }
      if (this.updateSettingSubscription) {
        this.updateSettingSubscription.unsubscribe();
      }
      this.unsubscribeForms();
      this.unsubscribeLeads();
    },
    unsubscribeLeads: function () {
      if (this.createLeadFeedbackSubscription) {
        this.createLeadFeedbackSubscription.unsubscribe();
      }
      if (this.createLeadSubscription) {
        this.createLeadSubscription.unsubscribe();
      }
      if (this.updateSourceSubscription) {
        this.updateSourceSubscription.unsubscribe();
      }
      if (this.createCampaignSubscription) {
        this.createCampaignSubscription.unsubscribe();
      }
      if (this.updateCampaignSubscription) {
        this.updateCampaignSubscription.unsubscribe();
      }
      if (this.updateLeadFeedbackSubscription) {
        this.updateLeadFeedbackSubscription.unsubscribe();
      }
      if (this.deleteCampaignSubscription) {
        this.deleteCampaignSubscription.unsubscribe();
      }
      this.clearLeads();
    },
    clearLeads: function () {
      this.UPDATE_CAMPAIGNS([]);
      this.UPDATE_ALL_CAMPAIGNS([]);
      this.UPDATE_ENABLED_SOURCES([]);
      this.UPDATE_LEADS([]);
      this.UPDATE_ALL_LEADS([]);
    },
    unsubscribeForms: function () {
      if (this.createFormSubscription) {
        this.createFormSubscription.unsubscribe();
      }
      if (this.deleteFormSubscription) {
        this.deleteFormSubscription.unsubscribe();
      }
      if (this.updateFormSubscription) {
        this.updateFormSubscription.unsubscribe();
      }
      this.clearForms();
    },
    clearForms: function () {
      this.UPDATE_FORMS([]);
      this.UPDATE_DAILY_FORMS([]);
      this.UPDATE_MTD_FORMS([]);
    },
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    searchFormTermUpdate: function () {
      $('.search-form').css('margin-top', '-60px');
      const searchTerm = $('#search-form-term')[0].value;
      if (searchTerm && searchTerm !== '') {
        if (_.size(searchTerm) < 3) {
          this.setMessage('v-notify-error', "Error!", 'Search Term must contain at least 3 characters');
          return;
        }
        this.UPDATE_SEARCH_FORM_TERM(searchTerm);
        if (this.$route.name !== 'search') {
          this.$router.push({name: 'search'});
        }
      }
    }
  }
};
</script>

<style>
@import "styles/master.css";
</style>
