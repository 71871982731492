import {formFields, simplifiedFormFields} from "@/constants";

export const countLeadPoolsByName = `query LeadPoolsByName(
  $name: String
  $sortDirection: ModelSortDirection
  $filter: ModelLeadPoolFilterInput
  $limit: Int
  $nextToken: String
) {
  leadPoolsByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
export const countFormsByStatusVerificationMinimal = `query FormsByStatusVerificaiton(
  $status: String
  $bankVerificationEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusVerificaiton(
    status: $status
    bankVerificationEpoch: $bankVerificationEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      firstName,
      lastName,
      mobileNumber,
      bankVerificationEpoch
      branchManager
      owner
      supervisor,
      updatedAt
    }
    nextToken
  }
}
`;
export const countFormsByPancardMinimal = `query FormsByPancard(
  $pancard: String
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByPancard(
    pancard: $pancard
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      bankVerificationEpoch
      createdAtEpoch
      previouslyAppliedDate
      version
    }
    nextToken
  }
}
`;
export const countFormsByPancardProductMinimal = `query FormsByPancardProduct(
  $pancard: String
  $product: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByPancardProduct(
    pancard: $pancard
    product: $product
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      bankVerificationEpoch
      createdAtEpoch
      previouslyAppliedDate
      version
    }
    nextToken
  }
}
`;
export const countFormsByReferenceNumberMinimal = `query FormsByReferenceNumber(
  $referenceNumber: String
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByReferenceNumber(
    referenceNumber: $referenceNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id        
    }
    nextToken
  }
}
`;
export const countFormsByStatusMinimal = `query FormsByStatus(
  $status: String
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
	  id
	  status
	  version
    }
    nextToken
  }
}
`;
export const countFormsByStatusOwnerMinimal = `query FormsByStatusOwner(
  $status: String
  $owner: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusOwner(
    status: $status
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      version
    }
    nextToken
  }
}
`;
export const countFormsByStatus = `query FormsByStatus(
  $status: String
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
	  ${_.keys(simplifiedFormFields).join('\n')}
    }
    nextToken
  }
}
`;
export const countFormsByStatusOwner = `query FormsByStatusOwner(
  $status: String
  $owner: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusOwner(
    status: $status
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(simplifiedFormFields).join('\n')}
    }
    nextToken
  }
}
`;
export const countFormsByEmployeeSubmittedDate = `query FormsByEmployeeSubmittedDate(
  $owner: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByEmployeeSubmittedDate(
    owner: $owner
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(simplifiedFormFields).join('\n')}
    }
    nextToken
  }
}
`;
export const countFormsByStatusSubmittedDate = `query FormsByStatusSubmittedDate(
  $status: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusSubmittedDate(
    status: $status
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(simplifiedFormFields).join('\n')}
    }
    nextToken
  }
}
`;
export const countLeadsByActionWithEpochMinimal = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      lead {
        id
        source
        product
      }
    }
    nextToken
  }
}
`;
export const countLeadsByOwnerWithEpochMinimal = `query LogsByOwnerWithEpoch(
  $owner: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByOwnerWithEpoch(
    owner: $owner
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      lead {
        id
        source
        product
      }
    }
    nextToken
  }
}
`;
export const countLeadsBySupervisorWithEpochMinimal = `query LogsBySupervisorWithEpoch(
  $supervisor: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsBySupervisorWithEpoch(
    supervisor: $supervisor
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      lead {
        id
        source
        product
      }
    }
    nextToken
  }
}
`;
export const countLeadsByBranchManagerWithEpochMinimal = `query LogsByBranchManagerWithEpoch(
  $branchManager: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByBranchManagerWithEpoch(
    branchManager: $branchManager
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      lead {
        id
        source
        product
      }
    }
    nextToken
  }
}
`;
export const countFormsByActionWithEpochMinimal = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      form {
        id
        owner
      }
    }
    nextToken
  }
}
`;
export const countFormsByActionWithEpoch = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      form {
        ${_.keys(simplifiedFormFields).join('\n')}
      }
    }
    nextToken
  }
}
`;
export const formsByEmployeeSubmittedDateWithHistory = `query FormsByEmployeeSubmittedDate(
  $owner: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByEmployeeSubmittedDate(
    owner: $owner
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(simplifiedFormFields).join('\n')}
      logs (limit: 999){
        items {
          id
          action
          actionEpoch
          actionType
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const formsByStatusSubmittedDateWithHistory = `query FormsByStatusSubmittedDate(
  $status: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusSubmittedDate(
    status: $status
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(simplifiedFormFields).join('\n')}
      logs (limit: 999){
        items {
          id
          action
          actionEpoch
          actionType
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const formsByActionWithEpochWithHistory = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      form {
        ${_.keys(simplifiedFormFields).join('\n')}
		logs (limit: 999){
		  items {
		    id
		    action
		    actionEpoch
		    actionType
		    owner
		  }
		  nextToken
		}
      }
    }
    nextToken
  }
}
`;
export const formsByEmployeeSubmittedDateWithHistoryAllFields = `query FormsByEmployeeSubmittedDate(
  $owner: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByEmployeeSubmittedDate(
    owner: $owner
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(formFields).join('\n')}
      logs (limit: 999){
        items {
          id
          action
          actionEpoch
          actionType
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const formsByStatusSubmittedDateWithHistoryAllFields = `query FormsByStatusSubmittedDate(
  $status: String
  $createdAtEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByStatusSubmittedDate(
    status: $status
    createdAtEpoch: $createdAtEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${_.keys(formFields).join('\n')}
      logs (limit: 999){
        items {
          id
          action
          actionEpoch
          actionType
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const formsByActionWithEpochWithHistoryAllFields = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      form {
        ${_.keys(formFields).join('\n')}
		logs (limit: 999){
		  items {
		    id
		    action
		    actionEpoch
		    actionType
		    owner
		  }
		  nextToken
		}
      }
    }
    nextToken
  }
}
`;
export const formsByActionWithEpochAllFields = `query FormsByActionWithEpoch(
  $action: String
  $actionEpoch: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByActionWithEpoch(
    action: $action
    actionEpoch: $actionEpoch
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      form {
        ${_.keys(formFields).join('\n')}
      }
    }
    nextToken
  }
}
`;

export const searchFormsWithHistoryAllFields = `query SearchForms(
  $filter: SearchableFormFilterInput
  $sort: SearchableFormSortInput
  $limit: Int
  $nextToken: String
) {
  searchForms(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {      
        ${_.keys(formFields).join('\n')}
		logs (limit: 999) {
		  items {
		    id
		    action
		    actionEpoch
		    actionType
		    owner
		  }
		  nextToken
		}
    }
    nextToken
  }
}
`;
export const publicListUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      branchId
      firstName
      lastName
      email
      picture
      jobTitle
      supervisor
    }
    nextToken
  }
}
`;
export const publicUsersListBySupervisor = `query UsersBySupervisor(
  $supervisor: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersBySupervisor(
    supervisor: $supervisor
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      branchId
      firstName
      lastName
      email
      picture
      jobTitle
      supervisor
    }
    nextToken
  }
}
`;
export const publicUsersListByJobTitle = `query UsersByJobTitle(
  $jobTitle: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByJobTitle(
    jobTitle: $jobTitle
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      branchId
      firstName
      lastName
      email
      picture
      jobTitle
      supervisor
    }
    nextToken
  }
}
`;
