import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {
	faAngleDown,
	faAsterisk,
	faBars,
	faBell,
	faBoxOpen,
	faCalendarCheck,
	faChartPie,
	faCheck,
	faClock,
	faCog,
	faEnvelope,
	faExpand,
	faEye,
	faFileInvoice,
	faFileUpload,
	faFilter,
	faFunnelDollar,
	faHome,
	faLink,
	faListUl,
	faLock,
	faMagnet,
	faMapMarker,
	faPause,
	faPhone,
	faPhoneVolume,
	faPlay,
	faPrint,
	faSearch,
	faSignOutAlt,
	faSpinner,
	faStar,
	faSync,
	faTimes,
	faTrashAlt,
	faUser
} from "@fortawesome/free-solid-svg-icons";
import {faCalendarAlt, faCopy, faEdit, faFileAlt, faFileExcel, faFilePdf,} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  install: function (Vue) {
    dom.watch();
    library.add(
        faAngleDown,
        faAsterisk,
        faBars,
        faBell,
        faBoxOpen,
        faCalendarAlt,
        faCalendarCheck,
        faChartPie,
        faCheck,
        faClock,
        faCog,
        faCopy,
        faEdit,
        faEnvelope,
        faEye,
        faExpand,
        faFileAlt,
        faFileExcel,
        faFileInvoice,
        faFilePdf,
        faFileUpload,
        faFilter,
        faFunnelDollar,
        faHome,
        faLink,
        faListUl,
        faLock,
        faMagnet,
        faMapMarker,
        faPause,
        faPhone,
        faPhoneVolume,
        faPlay,
        faPrint,
        faSearch,
        faSignOutAlt,
        faSpinner,
        faStar,
        faSync,
        faTimes,
        faTrashAlt,
        faUser
    );
    Vue.component("font-awesome-icon", FontAwesomeIcon);
  }
};
