/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      firstName
      lastName
      pancard
      status
      appId
      branchId
      addressLine1
      addressLine2
      age
      backOfficeOwner
      bankVerificationDate
      bankVerificationEpoch
      bankReceivedDate
      bankReceivedEpoch
      bankResultDate
      bankResultEpoch
      branchManager
      cardMailingAddress
      cardType
      cibilScore
      city
      company
      companyAddressLine1
      companyAddressLine2
      companyCity
      companyLandmark
      companyListed
      companyStation
      companyZipcode
      createdAt
      createdAtEpoch
      dateOfBirth
      dateNinetyDays
      dateNinetyDaysEpoch
      designation
      documentSubmissionLink
      education
      email
      emailOfficial
      employmentStatus
      extCCAvailableLimit1
      extCCAvailableLimit2
      extCCAvailableLimit3
      extCCAvailableLimit4
      extCCBank1
      extCCBank2
      extCCBank3
      extCCBank4
      extCCTotalLimit1
      extCCTotalLimit2
      extCCTotalLimit3
      extCCTotalLimit4
      extCCUsingFrom1
      extCCUsingFrom2
      extCCUsingFrom3
      extCCUsingFrom4
      fatherName
      gender
      landmark
      maritalStatus
      motherName
      mobileNumber
      mobileNumberAlternate
      nameDesired
      netMonthlyIncome
      offerType
      officeLandline
      officeExtension
      owner
      previouslyAppliedDate
      product
      referenceNumber
      referralBy
      remarks
      residenceType
      station
      statusJustifications
      stayingSince
      submittedBy
      submittedDocuments
      bankStatementPassword1
      bankStatementPassword2
      bankStatementPassword3
      extCCNumber
      extCCStatementPassword
      salarySlipPassword
      supervisor
      title
      updatedAt
      updatedAtEpoch
      updatedBy
      verifiedIncomeProgram
      yearsEmployed
      zipcode
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByPancardVerificaiton = /* GraphQL */ `
  query FormsByPancardVerificaiton(
    $pancard: String
    $bankVerificationEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByPancardVerificaiton(
      pancard: $pancard
      bankVerificationEpoch: $bankVerificationEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByEmployeeSubmittedDate = /* GraphQL */ `
  query FormsByEmployeeSubmittedDate(
    $owner: String
    $createdAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByEmployeeSubmittedDate(
      owner: $owner
      createdAtEpoch: $createdAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusSubmittedDate = /* GraphQL */ `
  query FormsByStatusSubmittedDate(
    $status: String
    $createdAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusSubmittedDate(
      status: $status
      createdAtEpoch: $createdAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusUpdatedDate = /* GraphQL */ `
  query FormsByStatusUpdatedDate(
    $status: String
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusUpdatedDate(
      status: $status
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusOwner = /* GraphQL */ `
  query FormsByStatusOwner(
    $status: String
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusOwner(
      status: $status
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusBackOffice = /* GraphQL */ `
  query FormsByStatusBackOffice(
    $status: String
    $backOfficeOwner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusBackOffice(
      status: $status
      backOfficeOwner: $backOfficeOwner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusVerificaiton = /* GraphQL */ `
  query FormsByStatusVerificaiton(
    $status: String
    $bankVerificationEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusVerificaiton(
      status: $status
      bankVerificationEpoch: $bankVerificationEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatusNinetyDays = /* GraphQL */ `
  query FormsByStatusNinetyDays(
    $status: String
    $dateNinetyDaysEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatusNinetyDays(
      status: $status
      dateNinetyDaysEpoch: $dateNinetyDaysEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByBranchId = /* GraphQL */ `
  query FormsByBranchId(
    $branchId: Int
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByBranchId(
      branchId: $branchId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByEmployee = /* GraphQL */ `
  query FormsByEmployee(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByEmployee(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByStatus = /* GraphQL */ `
  query FormsByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByPancard = /* GraphQL */ `
  query FormsByPancard(
    $pancard: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByPancard(
      pancard: $pancard
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByPancardProduct = /* GraphQL */ `
  query FormsByPancardProduct(
    $pancard: String
    $product: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByPancardProduct(
      pancard: $pancard
      product: $product
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByReferenceNumber = /* GraphQL */ `
  query FormsByReferenceNumber(
    $referenceNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByReferenceNumber(
      referenceNumber: $referenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByAppId = /* GraphQL */ `
  query FormsByAppId(
    $appId: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByAppId(
      appId: $appId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const searchForms = /* GraphQL */ `
  query SearchForms(
    $filter: SearchableFormFilterInput
    $sort: SearchableFormSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchForms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
      total
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      formOwner
      expirationEpoch
      updatedAt
      form {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      pool {
        id
        name
        sources
        members
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        duplicatesCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        logs {
          nextToken
        }
      }
      source {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        action
        createdAt
        actionEpoch
        actionType
        owner
        supervisor
        branchManager
        formOwner
        expirationEpoch
        updatedAt
        form {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        pool {
          id
          name
          sources
          members
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          duplicatesCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
        source {
          id
          name
          enabled
          leads
          leadCount
          totalCount
          priority
          product
          allocatedMembers
          isCrossLeadsSource
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          enableAtEpoch
          duplicates
          version
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const formsByActionWithEpoch = /* GraphQL */ `
  query FormsByActionWithEpoch(
    $action: String
    $actionEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formsByActionWithEpoch(
      action: $action
      actionEpoch: $actionEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        createdAt
        actionEpoch
        actionType
        owner
        supervisor
        branchManager
        formOwner
        expirationEpoch
        updatedAt
        form {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        pool {
          id
          name
          sources
          members
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          duplicatesCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
        source {
          id
          name
          enabled
          leads
          leadCount
          totalCount
          priority
          product
          allocatedMembers
          isCrossLeadsSource
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          enableAtEpoch
          duplicates
          version
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const logsByOwnerWithEpoch = /* GraphQL */ `
  query LogsByOwnerWithEpoch(
    $owner: String
    $actionEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByOwnerWithEpoch(
      owner: $owner
      actionEpoch: $actionEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        createdAt
        actionEpoch
        actionType
        owner
        supervisor
        branchManager
        formOwner
        expirationEpoch
        updatedAt
        form {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        pool {
          id
          name
          sources
          members
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          duplicatesCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
        source {
          id
          name
          enabled
          leads
          leadCount
          totalCount
          priority
          product
          allocatedMembers
          isCrossLeadsSource
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          enableAtEpoch
          duplicates
          version
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const logsBySupervisorWithEpoch = /* GraphQL */ `
  query LogsBySupervisorWithEpoch(
    $supervisor: String
    $actionEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsBySupervisorWithEpoch(
      supervisor: $supervisor
      actionEpoch: $actionEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        createdAt
        actionEpoch
        actionType
        owner
        supervisor
        branchManager
        formOwner
        expirationEpoch
        updatedAt
        form {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        pool {
          id
          name
          sources
          members
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          duplicatesCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
        source {
          id
          name
          enabled
          leads
          leadCount
          totalCount
          priority
          product
          allocatedMembers
          isCrossLeadsSource
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          enableAtEpoch
          duplicates
          version
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const logsByBranchManagerWithEpoch = /* GraphQL */ `
  query LogsByBranchManagerWithEpoch(
    $branchManager: String
    $actionEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByBranchManagerWithEpoch(
      branchManager: $branchManager
      actionEpoch: $actionEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        createdAt
        actionEpoch
        actionType
        owner
        supervisor
        branchManager
        formOwner
        expirationEpoch
        updatedAt
        form {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        pool {
          id
          name
          sources
          members
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          duplicatesCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
        source {
          id
          name
          enabled
          leads
          leadCount
          totalCount
          priority
          product
          allocatedMembers
          isCrossLeadsSource
          activeCount
          untouchedCount
          closedCount
          interestedCount
          callLaterCount
          noContactCount
          reusableCount
          unusableCount
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          enableAtEpoch
          duplicates
          version
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      nextToken
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      name
      isEnabled
      alltime
      daily
      selectable
      editable
      allFields
      justifications
      currentMonth
      lastMonth
      workflow
      createdAt
      updatedAt
    }
  }
`;
export const listStatuss = /* GraphQL */ `
  query ListStatuss(
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isEnabled
        alltime
        daily
        selectable
        editable
        allFields
        justifications
        currentMonth
        lastMonth
        workflow
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      isEnabled
      eligibilityRequirements
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isEnabled
        eligibilityRequirements
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      identityId
      username
      isEnabled
      email
      branchId
      firstName
      lastName
      phone
      preferredUsername
      nickname
      gender
      phoneVerified
      emailVerified
      birthDate
      picture
      website
      jobTitle
      supervisor
      lastOnline
      lastOnlineEpoch
      createdAt
      updatedAt
      pushSubscriptions
      products
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identityId
        username
        isEnabled
        email
        branchId
        firstName
        lastName
        phone
        preferredUsername
        nickname
        gender
        phoneVerified
        emailVerified
        birthDate
        picture
        website
        jobTitle
        supervisor
        lastOnline
        lastOnlineEpoch
        createdAt
        updatedAt
        pushSubscriptions
        products
      }
      nextToken
    }
  }
`;
export const usersBySupervisor = /* GraphQL */ `
  query UsersBySupervisor(
    $supervisor: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySupervisor(
      supervisor: $supervisor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        username
        isEnabled
        email
        branchId
        firstName
        lastName
        phone
        preferredUsername
        nickname
        gender
        phoneVerified
        emailVerified
        birthDate
        picture
        website
        jobTitle
        supervisor
        lastOnline
        lastOnlineEpoch
        createdAt
        updatedAt
        pushSubscriptions
        products
      }
      nextToken
    }
  }
`;
export const usersByJobTitle = /* GraphQL */ `
  query UsersByJobTitle(
    $jobTitle: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByJobTitle(
      jobTitle: $jobTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        username
        isEnabled
        email
        branchId
        firstName
        lastName
        phone
        preferredUsername
        nickname
        gender
        phoneVerified
        emailVerified
        birthDate
        picture
        website
        jobTitle
        supervisor
        lastOnline
        lastOnlineEpoch
        createdAt
        updatedAt
        pushSubscriptions
        products
      }
      nextToken
    }
  }
`;
export const usersByJobTitleLastOnline = /* GraphQL */ `
  query UsersByJobTitleLastOnline(
    $jobTitle: String
    $lastOnlineEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByJobTitleLastOnline(
      jobTitle: $jobTitle
      lastOnlineEpoch: $lastOnlineEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        username
        isEnabled
        email
        branchId
        firstName
        lastName
        phone
        preferredUsername
        nickname
        gender
        phoneVerified
        emailVerified
        birthDate
        picture
        website
        jobTitle
        supervisor
        lastOnline
        lastOnlineEpoch
        createdAt
        updatedAt
        pushSubscriptions
        products
      }
      nextToken
    }
  }
`;
export const getBranch = /* GraphQL */ `
  query GetBranch($id: ID!) {
    getBranch(id: $id) {
      id
      number
      name
      location
      isEnabled
      managers
      createdAt
      updatedAt
      yesLGCode
    }
  }
`;
export const listBranchs = /* GraphQL */ `
  query ListBranchs(
    $filter: ModelBranchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBranchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        name
        location
        isEnabled
        managers
        createdAt
        updatedAt
        yesLGCode
      }
      nextToken
    }
  }
`;
export const getTitle = /* GraphQL */ `
  query GetTitle($id: ID!) {
    getTitle(id: $id) {
      id
      name
      access
      users
      pages
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const listTitles = /* GraphQL */ `
  query ListTitles(
    $filter: ModelTitleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        access
        users
        pages
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeadPool = /* GraphQL */ `
  query GetLeadPool($id: ID!) {
    getLeadPool(id: $id) {
      id
      name
      sources
      members
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      duplicatesCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLeadPools = /* GraphQL */ `
  query ListLeadPools(
    $filter: ModelLeadPoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadPools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sources
        members
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        duplicatesCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadPoolsByName = /* GraphQL */ `
  query LeadPoolsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadPoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadPoolsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sources
        members
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        duplicatesCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLeadSource = /* GraphQL */ `
  query GetLeadSource($id: ID!) {
    getLeadSource(id: $id) {
      id
      name
      enabled
      leads
      leadCount
      totalCount
      priority
      product
      allocatedMembers
      isCrossLeadsSource
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      enableAtEpoch
      duplicates
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLeadSources = /* GraphQL */ `
  query ListLeadSources(
    $filter: ModelLeadSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sourcesByEnabled = /* GraphQL */ `
  query SourcesByEnabled(
    $enabled: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourcesByEnabled(
      enabled: $enabled
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sourcesByIsCrossLeadsSource = /* GraphQL */ `
  query SourcesByIsCrossLeadsSource(
    $isCrossLeadsSource: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourcesByIsCrossLeadsSource(
      isCrossLeadsSource: $isCrossLeadsSource
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sourcesByEnabledAtEpoch = /* GraphQL */ `
  query SourcesByEnabledAtEpoch(
    $enabled: String
    $enableAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourcesByEnabledAtEpoch(
      enabled: $enabled
      enableAtEpoch: $enableAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      source
      leadNumber
      customerName
      mobileNumber
      email
      city
      address
      pancard
      assignedTo
      feedback
      product
      isAssigned
      isReusable
      isUnusable
      isInterested
      isInterestedOther
      isNoContact
      isClosed
      isCallLater
      callbackEpoch
      remarks
      noContactAttempt
      referralBy
      referralOwner
      interestedProducts
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      forms {
        items {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        nextToken
      }
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsBySource = /* GraphQL */ `
  query LeadsBySource(
    $source: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsBySource(
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByProductMobileNumber = /* GraphQL */ `
  query LeadsByProductMobileNumber(
    $product: String
    $mobileNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByProductMobileNumber(
      product: $product
      mobileNumber: $mobileNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByAssignedTo = /* GraphQL */ `
  query LeadsByAssignedTo(
    $assignedTo: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByAssignedTo(
      assignedTo: $assignedTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByIsAssigned = /* GraphQL */ `
  query LeadsByIsAssigned(
    $isAssigned: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByIsAssigned(
      isAssigned: $isAssigned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByIsInterestedOther = /* GraphQL */ `
  query LeadsByIsInterestedOther(
    $isInterestedOther: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByIsInterestedOther(
      isInterestedOther: $isInterestedOther
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByAssignedToVersion = /* GraphQL */ `
  query LeadsByAssignedToVersion(
    $assignedTo: String
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByAssignedToVersion(
      assignedTo: $assignedTo
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByAssignedToSource = /* GraphQL */ `
  query LeadsByAssignedToSource(
    $assignedTo: String
    $source: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByAssignedToSource(
      assignedTo: $assignedTo
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsBySourceIsAssigned = /* GraphQL */ `
  query LeadsBySourceIsAssigned(
    $source: String
    $isAssigned: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsBySourceIsAssigned(
      source: $source
      isAssigned: $isAssigned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByInterestedUpdatedEpoch = /* GraphQL */ `
  query LeadsByInterestedUpdatedEpoch(
    $isInterested: String
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByInterestedUpdatedEpoch(
      isInterested: $isInterested
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByCallLaterUpdatedEpoch = /* GraphQL */ `
  query LeadsByCallLaterUpdatedEpoch(
    $isCallLater: String
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByCallLaterUpdatedEpoch(
      isCallLater: $isCallLater
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByReusableUpdatedEpoch = /* GraphQL */ `
  query LeadsByReusableUpdatedEpoch(
    $isReusable: String
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByReusableUpdatedEpoch(
      isReusable: $isReusable
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByNoContactUpdatedEpoch = /* GraphQL */ `
  query LeadsByNoContactUpdatedEpoch(
    $isNoContact: String
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByNoContactUpdatedEpoch(
      isNoContact: $isNoContact
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByNoContactAttemptUpdatedAtEpoch = /* GraphQL */ `
  query LeadsByNoContactAttemptUpdatedAtEpoch(
    $noContactAttempt: Int
    $updatedAtEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByNoContactAttemptUpdatedAtEpoch(
      noContactAttempt: $noContactAttempt
      updatedAtEpoch: $updatedAtEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByInterestedCallbackEpoch = /* GraphQL */ `
  query LeadsByInterestedCallbackEpoch(
    $isInterested: String
    $callbackEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByInterestedCallbackEpoch(
      isInterested: $isInterested
      callbackEpoch: $callbackEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const leadsByCallLaterCallbackEpoch = /* GraphQL */ `
  query LeadsByCallLaterCallbackEpoch(
    $isCallLater: String
    $callbackEpoch: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByCallLaterCallbackEpoch(
      isCallLater: $isCallLater
      callbackEpoch: $callbackEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLeadFeedback = /* GraphQL */ `
  query GetLeadFeedback($id: ID!) {
    getLeadFeedback(id: $id) {
      id
      category
      name
      isEnabled
      products
      createdAt
      updatedAt
    }
  }
`;
export const listLeadFeedbacks = /* GraphQL */ `
  query ListLeadFeedbacks(
    $filter: ModelLeadFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        name
        isEnabled
        products
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      name
      value
      type
      lastUpdatedBy
      updatedAt
      updatedAtEpoch
      createdAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        type
        lastUpdatedBy
        updatedAt
        updatedAtEpoch
        createdAt
      }
      nextToken
    }
  }
`;
