/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLogSupervisor = /* GraphQL */ `
  subscription OnCreateLogSupervisor($action: String, $supervisor: String) {
    onCreateLogSupervisor(action: $action, supervisor: $supervisor) {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      formOwner
      expirationEpoch
      updatedAt
      form {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      pool {
        id
        name
        sources
        members
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        duplicatesCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        logs {
          nextToken
        }
      }
      source {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const onCreateLogBranchManager = /* GraphQL */ `
  subscription OnCreateLogBranchManager(
    $action: String
    $branchManager: String
  ) {
    onCreateLogBranchManager(action: $action, branchManager: $branchManager) {
      id
      action
      createdAt
      actionEpoch
      actionType
      owner
      supervisor
      branchManager
      formOwner
      expirationEpoch
      updatedAt
      form {
        id
        firstName
        lastName
        pancard
        status
        appId
        branchId
        addressLine1
        addressLine2
        age
        backOfficeOwner
        bankVerificationDate
        bankVerificationEpoch
        bankReceivedDate
        bankReceivedEpoch
        bankResultDate
        bankResultEpoch
        branchManager
        cardMailingAddress
        cardType
        cibilScore
        city
        company
        companyAddressLine1
        companyAddressLine2
        companyCity
        companyLandmark
        companyListed
        companyStation
        companyZipcode
        createdAt
        createdAtEpoch
        dateOfBirth
        dateNinetyDays
        dateNinetyDaysEpoch
        designation
        documentSubmissionLink
        education
        email
        emailOfficial
        employmentStatus
        extCCAvailableLimit1
        extCCAvailableLimit2
        extCCAvailableLimit3
        extCCAvailableLimit4
        extCCBank1
        extCCBank2
        extCCBank3
        extCCBank4
        extCCTotalLimit1
        extCCTotalLimit2
        extCCTotalLimit3
        extCCTotalLimit4
        extCCUsingFrom1
        extCCUsingFrom2
        extCCUsingFrom3
        extCCUsingFrom4
        fatherName
        gender
        landmark
        maritalStatus
        motherName
        mobileNumber
        mobileNumberAlternate
        nameDesired
        netMonthlyIncome
        offerType
        officeLandline
        officeExtension
        owner
        previouslyAppliedDate
        product
        referenceNumber
        referralBy
        remarks
        residenceType
        station
        statusJustifications
        stayingSince
        submittedBy
        submittedDocuments
        bankStatementPassword1
        bankStatementPassword2
        bankStatementPassword3
        extCCNumber
        extCCStatementPassword
        salarySlipPassword
        supervisor
        title
        updatedAt
        updatedAtEpoch
        updatedBy
        verifiedIncomeProgram
        yearsEmployed
        zipcode
        version
        logs {
          nextToken
        }
        lead {
          id
          source
          leadNumber
          customerName
          mobileNumber
          email
          city
          address
          pancard
          assignedTo
          feedback
          product
          isAssigned
          isReusable
          isUnusable
          isInterested
          isInterestedOther
          isNoContact
          isClosed
          isCallLater
          callbackEpoch
          remarks
          noContactAttempt
          referralBy
          referralOwner
          interestedProducts
          createdAt
          createdAtEpoch
          updatedAt
          updatedAtEpoch
          version
        }
      }
      pool {
        id
        name
        sources
        members
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        duplicatesCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        logs {
          nextToken
        }
      }
      source {
        id
        name
        enabled
        leads
        leadCount
        totalCount
        priority
        product
        allocatedMembers
        isCrossLeadsSource
        activeCount
        untouchedCount
        closedCount
        interestedCount
        callLaterCount
        noContactCount
        reusableCount
        unusableCount
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        enableAtEpoch
        duplicates
        version
        logs {
          nextToken
        }
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const onCreateForm = /* GraphQL */ `
  subscription OnCreateForm {
    onCreateForm {
      id
      firstName
      lastName
      pancard
      status
      appId
      branchId
      addressLine1
      addressLine2
      age
      backOfficeOwner
      bankVerificationDate
      bankVerificationEpoch
      bankReceivedDate
      bankReceivedEpoch
      bankResultDate
      bankResultEpoch
      branchManager
      cardMailingAddress
      cardType
      cibilScore
      city
      company
      companyAddressLine1
      companyAddressLine2
      companyCity
      companyLandmark
      companyListed
      companyStation
      companyZipcode
      createdAt
      createdAtEpoch
      dateOfBirth
      dateNinetyDays
      dateNinetyDaysEpoch
      designation
      documentSubmissionLink
      education
      email
      emailOfficial
      employmentStatus
      extCCAvailableLimit1
      extCCAvailableLimit2
      extCCAvailableLimit3
      extCCAvailableLimit4
      extCCBank1
      extCCBank2
      extCCBank3
      extCCBank4
      extCCTotalLimit1
      extCCTotalLimit2
      extCCTotalLimit3
      extCCTotalLimit4
      extCCUsingFrom1
      extCCUsingFrom2
      extCCUsingFrom3
      extCCUsingFrom4
      fatherName
      gender
      landmark
      maritalStatus
      motherName
      mobileNumber
      mobileNumberAlternate
      nameDesired
      netMonthlyIncome
      offerType
      officeLandline
      officeExtension
      owner
      previouslyAppliedDate
      product
      referenceNumber
      referralBy
      remarks
      residenceType
      station
      statusJustifications
      stayingSince
      submittedBy
      submittedDocuments
      bankStatementPassword1
      bankStatementPassword2
      bankStatementPassword3
      extCCNumber
      extCCStatementPassword
      salarySlipPassword
      supervisor
      title
      updatedAt
      updatedAtEpoch
      updatedBy
      verifiedIncomeProgram
      yearsEmployed
      zipcode
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateForm = /* GraphQL */ `
  subscription OnUpdateForm {
    onUpdateForm {
      id
      firstName
      lastName
      pancard
      status
      appId
      branchId
      addressLine1
      addressLine2
      age
      backOfficeOwner
      bankVerificationDate
      bankVerificationEpoch
      bankReceivedDate
      bankReceivedEpoch
      bankResultDate
      bankResultEpoch
      branchManager
      cardMailingAddress
      cardType
      cibilScore
      city
      company
      companyAddressLine1
      companyAddressLine2
      companyCity
      companyLandmark
      companyListed
      companyStation
      companyZipcode
      createdAt
      createdAtEpoch
      dateOfBirth
      dateNinetyDays
      dateNinetyDaysEpoch
      designation
      documentSubmissionLink
      education
      email
      emailOfficial
      employmentStatus
      extCCAvailableLimit1
      extCCAvailableLimit2
      extCCAvailableLimit3
      extCCAvailableLimit4
      extCCBank1
      extCCBank2
      extCCBank3
      extCCBank4
      extCCTotalLimit1
      extCCTotalLimit2
      extCCTotalLimit3
      extCCTotalLimit4
      extCCUsingFrom1
      extCCUsingFrom2
      extCCUsingFrom3
      extCCUsingFrom4
      fatherName
      gender
      landmark
      maritalStatus
      motherName
      mobileNumber
      mobileNumberAlternate
      nameDesired
      netMonthlyIncome
      offerType
      officeLandline
      officeExtension
      owner
      previouslyAppliedDate
      product
      referenceNumber
      referralBy
      remarks
      residenceType
      station
      statusJustifications
      stayingSince
      submittedBy
      submittedDocuments
      bankStatementPassword1
      bankStatementPassword2
      bankStatementPassword3
      extCCNumber
      extCCStatementPassword
      salarySlipPassword
      supervisor
      title
      updatedAt
      updatedAtEpoch
      updatedBy
      verifiedIncomeProgram
      yearsEmployed
      zipcode
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteForm = /* GraphQL */ `
  subscription OnDeleteForm {
    onDeleteForm {
      id
      firstName
      lastName
      pancard
      status
      appId
      branchId
      addressLine1
      addressLine2
      age
      backOfficeOwner
      bankVerificationDate
      bankVerificationEpoch
      bankReceivedDate
      bankReceivedEpoch
      bankResultDate
      bankResultEpoch
      branchManager
      cardMailingAddress
      cardType
      cibilScore
      city
      company
      companyAddressLine1
      companyAddressLine2
      companyCity
      companyLandmark
      companyListed
      companyStation
      companyZipcode
      createdAt
      createdAtEpoch
      dateOfBirth
      dateNinetyDays
      dateNinetyDaysEpoch
      designation
      documentSubmissionLink
      education
      email
      emailOfficial
      employmentStatus
      extCCAvailableLimit1
      extCCAvailableLimit2
      extCCAvailableLimit3
      extCCAvailableLimit4
      extCCBank1
      extCCBank2
      extCCBank3
      extCCBank4
      extCCTotalLimit1
      extCCTotalLimit2
      extCCTotalLimit3
      extCCTotalLimit4
      extCCUsingFrom1
      extCCUsingFrom2
      extCCUsingFrom3
      extCCUsingFrom4
      fatherName
      gender
      landmark
      maritalStatus
      motherName
      mobileNumber
      mobileNumberAlternate
      nameDesired
      netMonthlyIncome
      offerType
      officeLandline
      officeExtension
      owner
      previouslyAppliedDate
      product
      referenceNumber
      referralBy
      remarks
      residenceType
      station
      statusJustifications
      stayingSince
      submittedBy
      submittedDocuments
      bankStatementPassword1
      bankStatementPassword2
      bankStatementPassword3
      extCCNumber
      extCCStatementPassword
      salarySlipPassword
      supervisor
      title
      updatedAt
      updatedAtEpoch
      updatedBy
      verifiedIncomeProgram
      yearsEmployed
      zipcode
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
      lead {
        id
        source
        leadNumber
        customerName
        mobileNumber
        email
        city
        address
        pancard
        assignedTo
        feedback
        product
        isAssigned
        isReusable
        isUnusable
        isInterested
        isInterestedOther
        isNoContact
        isClosed
        isCallLater
        callbackEpoch
        remarks
        noContactAttempt
        referralBy
        referralOwner
        interestedProducts
        createdAt
        createdAtEpoch
        updatedAt
        updatedAtEpoch
        version
        forms {
          nextToken
        }
        logs {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStatus = /* GraphQL */ `
  subscription OnCreateStatus {
    onCreateStatus {
      id
      name
      isEnabled
      alltime
      daily
      selectable
      editable
      allFields
      justifications
      currentMonth
      lastMonth
      workflow
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStatus = /* GraphQL */ `
  subscription OnUpdateStatus {
    onUpdateStatus {
      id
      name
      isEnabled
      alltime
      daily
      selectable
      editable
      allFields
      justifications
      currentMonth
      lastMonth
      workflow
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStatus = /* GraphQL */ `
  subscription OnDeleteStatus {
    onDeleteStatus {
      id
      name
      isEnabled
      alltime
      daily
      selectable
      editable
      allFields
      justifications
      currentMonth
      lastMonth
      workflow
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      isEnabled
      eligibilityRequirements
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      isEnabled
      eligibilityRequirements
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      isEnabled
      eligibilityRequirements
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      identityId
      username
      isEnabled
      email
      branchId
      firstName
      lastName
      phone
      preferredUsername
      nickname
      gender
      phoneVerified
      emailVerified
      birthDate
      picture
      website
      jobTitle
      supervisor
      lastOnline
      lastOnlineEpoch
      createdAt
      updatedAt
      pushSubscriptions
      products
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      identityId
      username
      isEnabled
      email
      branchId
      firstName
      lastName
      phone
      preferredUsername
      nickname
      gender
      phoneVerified
      emailVerified
      birthDate
      picture
      website
      jobTitle
      supervisor
      lastOnline
      lastOnlineEpoch
      createdAt
      updatedAt
      pushSubscriptions
      products
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      identityId
      username
      isEnabled
      email
      branchId
      firstName
      lastName
      phone
      preferredUsername
      nickname
      gender
      phoneVerified
      emailVerified
      birthDate
      picture
      website
      jobTitle
      supervisor
      lastOnline
      lastOnlineEpoch
      createdAt
      updatedAt
      pushSubscriptions
      products
    }
  }
`;
export const onCreateBranch = /* GraphQL */ `
  subscription OnCreateBranch {
    onCreateBranch {
      id
      number
      name
      location
      isEnabled
      managers
      createdAt
      updatedAt
      yesLGCode
    }
  }
`;
export const onUpdateBranch = /* GraphQL */ `
  subscription OnUpdateBranch {
    onUpdateBranch {
      id
      number
      name
      location
      isEnabled
      managers
      createdAt
      updatedAt
      yesLGCode
    }
  }
`;
export const onDeleteBranch = /* GraphQL */ `
  subscription OnDeleteBranch {
    onDeleteBranch {
      id
      number
      name
      location
      isEnabled
      managers
      createdAt
      updatedAt
      yesLGCode
    }
  }
`;
export const onCreateTitle = /* GraphQL */ `
  subscription OnCreateTitle {
    onCreateTitle {
      id
      name
      access
      users
      pages
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTitle = /* GraphQL */ `
  subscription OnUpdateTitle {
    onUpdateTitle {
      id
      name
      access
      users
      pages
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTitle = /* GraphQL */ `
  subscription OnDeleteTitle {
    onDeleteTitle {
      id
      name
      access
      users
      pages
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLeadPool = /* GraphQL */ `
  subscription OnCreateLeadPool {
    onCreateLeadPool {
      id
      name
      sources
      members
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      duplicatesCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateLeadPool = /* GraphQL */ `
  subscription OnUpdateLeadPool {
    onUpdateLeadPool {
      id
      name
      sources
      members
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      duplicatesCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteLeadPool = /* GraphQL */ `
  subscription OnDeleteLeadPool {
    onDeleteLeadPool {
      id
      name
      sources
      members
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      duplicatesCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLeadSource = /* GraphQL */ `
  subscription OnCreateLeadSource {
    onCreateLeadSource {
      id
      name
      enabled
      leads
      leadCount
      totalCount
      priority
      product
      allocatedMembers
      isCrossLeadsSource
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      enableAtEpoch
      duplicates
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateLeadSource = /* GraphQL */ `
  subscription OnUpdateLeadSource {
    onUpdateLeadSource {
      id
      name
      enabled
      leads
      leadCount
      totalCount
      priority
      product
      allocatedMembers
      isCrossLeadsSource
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      enableAtEpoch
      duplicates
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteLeadSource = /* GraphQL */ `
  subscription OnDeleteLeadSource {
    onDeleteLeadSource {
      id
      name
      enabled
      leads
      leadCount
      totalCount
      priority
      product
      allocatedMembers
      isCrossLeadsSource
      activeCount
      untouchedCount
      closedCount
      interestedCount
      callLaterCount
      noContactCount
      reusableCount
      unusableCount
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      enableAtEpoch
      duplicates
      version
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLead = /* GraphQL */ `
  subscription OnCreateLead {
    onCreateLead {
      id
      source
      leadNumber
      customerName
      mobileNumber
      email
      city
      address
      pancard
      assignedTo
      feedback
      product
      isAssigned
      isReusable
      isUnusable
      isInterested
      isInterestedOther
      isNoContact
      isClosed
      isCallLater
      callbackEpoch
      remarks
      noContactAttempt
      referralBy
      referralOwner
      interestedProducts
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      forms {
        items {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        nextToken
      }
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateLead = /* GraphQL */ `
  subscription OnUpdateLead {
    onUpdateLead {
      id
      source
      leadNumber
      customerName
      mobileNumber
      email
      city
      address
      pancard
      assignedTo
      feedback
      product
      isAssigned
      isReusable
      isUnusable
      isInterested
      isInterestedOther
      isNoContact
      isClosed
      isCallLater
      callbackEpoch
      remarks
      noContactAttempt
      referralBy
      referralOwner
      interestedProducts
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      forms {
        items {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        nextToken
      }
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteLead = /* GraphQL */ `
  subscription OnDeleteLead {
    onDeleteLead {
      id
      source
      leadNumber
      customerName
      mobileNumber
      email
      city
      address
      pancard
      assignedTo
      feedback
      product
      isAssigned
      isReusable
      isUnusable
      isInterested
      isInterestedOther
      isNoContact
      isClosed
      isCallLater
      callbackEpoch
      remarks
      noContactAttempt
      referralBy
      referralOwner
      interestedProducts
      createdAt
      createdAtEpoch
      updatedAt
      updatedAtEpoch
      version
      forms {
        items {
          id
          firstName
          lastName
          pancard
          status
          appId
          branchId
          addressLine1
          addressLine2
          age
          backOfficeOwner
          bankVerificationDate
          bankVerificationEpoch
          bankReceivedDate
          bankReceivedEpoch
          bankResultDate
          bankResultEpoch
          branchManager
          cardMailingAddress
          cardType
          cibilScore
          city
          company
          companyAddressLine1
          companyAddressLine2
          companyCity
          companyLandmark
          companyListed
          companyStation
          companyZipcode
          createdAt
          createdAtEpoch
          dateOfBirth
          dateNinetyDays
          dateNinetyDaysEpoch
          designation
          documentSubmissionLink
          education
          email
          emailOfficial
          employmentStatus
          extCCAvailableLimit1
          extCCAvailableLimit2
          extCCAvailableLimit3
          extCCAvailableLimit4
          extCCBank1
          extCCBank2
          extCCBank3
          extCCBank4
          extCCTotalLimit1
          extCCTotalLimit2
          extCCTotalLimit3
          extCCTotalLimit4
          extCCUsingFrom1
          extCCUsingFrom2
          extCCUsingFrom3
          extCCUsingFrom4
          fatherName
          gender
          landmark
          maritalStatus
          motherName
          mobileNumber
          mobileNumberAlternate
          nameDesired
          netMonthlyIncome
          offerType
          officeLandline
          officeExtension
          owner
          previouslyAppliedDate
          product
          referenceNumber
          referralBy
          remarks
          residenceType
          station
          statusJustifications
          stayingSince
          submittedBy
          submittedDocuments
          bankStatementPassword1
          bankStatementPassword2
          bankStatementPassword3
          extCCNumber
          extCCStatementPassword
          salarySlipPassword
          supervisor
          title
          updatedAt
          updatedAtEpoch
          updatedBy
          verifiedIncomeProgram
          yearsEmployed
          zipcode
          version
        }
        nextToken
      }
      logs {
        items {
          id
          action
          createdAt
          actionEpoch
          actionType
          owner
          supervisor
          branchManager
          formOwner
          expirationEpoch
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLeadFeedback = /* GraphQL */ `
  subscription OnCreateLeadFeedback {
    onCreateLeadFeedback {
      id
      category
      name
      isEnabled
      products
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLeadFeedback = /* GraphQL */ `
  subscription OnUpdateLeadFeedback {
    onUpdateLeadFeedback {
      id
      category
      name
      isEnabled
      products
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLeadFeedback = /* GraphQL */ `
  subscription OnDeleteLeadFeedback {
    onDeleteLeadFeedback {
      id
      category
      name
      isEnabled
      products
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSetting = /* GraphQL */ `
  subscription OnCreateSetting {
    onCreateSetting {
      id
      name
      value
      type
      lastUpdatedBy
      updatedAt
      updatedAtEpoch
      createdAt
    }
  }
`;
export const onUpdateSetting = /* GraphQL */ `
  subscription OnUpdateSetting {
    onUpdateSetting {
      id
      name
      value
      type
      lastUpdatedBy
      updatedAt
      updatedAtEpoch
      createdAt
    }
  }
`;
export const onDeleteSetting = /* GraphQL */ `
  subscription OnDeleteSetting {
    onDeleteSetting {
      id
      name
      value
      type
      lastUpdatedBy
      updatedAt
      updatedAtEpoch
      createdAt
    }
  }
`;
