const pushNotificationCategories = {
  PROSPECTS: "Prospects",
  LEADS: "Leads",
  FORMS: 'Forms'
};
const leadFeedbackCategories = {
  CALL_LATER: "CALL LATER",
  CLOSED: "CLOSED",
  INTERESTED: "INTERESTED",
  NO_CONTACT: "NO CONTACT",
  REUSABLE: "REUSABLE",
  UNUSABLE: "UNUSABLE",
};
const priorities = {
  URGENT: {
    id: 'URGENT',
    name: 'Urgent',
    level: 1
  },
  HIGH: {
    id: 'HIGH',
    name: 'High',
    level: 2
  },
  MEDIUM: {
    id: 'MEDIUM',
    name: 'Medium',
    level: 3
  },
  LOW: {
    id: 'LOW',
    name: 'Low',
    level: 4
  },
  NONE: {
    id: 'NONE',
    name: 'None',
    level: 5
  }
};
const oclPincodes = [
  400000,
  400001,
  400002,
  400003,
  400004,
  400005,
  400006,
  400007,
  400008,
  400009,
  400010,
  400011,
  400012,
  400013,
  400014,
  400015,
  400016,
  400017,
  400018,
  400019,
  400020,
  400021,
  400022,
  400023,
  400024,
  400025,
  400026,
  400027,
  400028,
  400029,
  400030,
  400031,
  400032,
  400033,
  400034,
  400035,
  400036,
  400037,
  400038,
  400039,
  400040,
  400041,
  400042,
  400043,
  400044,
  400045,
  400046,
  400047,
  400048,
  400049,
  400050,
  400051,
  400052,
  400053,
  400054,
  400055,
  400056,
  400057,
  400058,
  400059,
  400060,
  400061,
  400062,
  400063,
  400064,
  400065,
  400066,
  400067,
  400068,
  400069,
  400070,
  400071,
  400072,
  400073,
  400074,
  400075,
  400076,
  400077,
  400078,
  400079,
  400080,
  400081,
  400082,
  400083,
  400084,
  400085,
  400086,
  400087,
  400088,
  400089,
  400090,
  400091,
  400092,
  400093,
  400094,
  400095,
  400096,
  400097,
  400098,
  400099,
  400100,
  400101,
  400102,
  400103,
  400104,
  400105,
  400107,
  400108,
  400109,
  400115,
  400116,
  400117,
  400120,
  400121,
  400125,
  400126,
  400128,
  400130,
  400133,
  400136,
  400160,
  400161,
  400162,
  400164,
  400181,
  400183,
  400200,
  400202,
  400203,
  400204,
  400205,
  400206,
  400207,
  400208,
  400209,
  400210,
  400212,
  400213,
  400214,
  400216,
  400218,
  400221,
  400226,
  400250,
  400251,
  400286,
  400297,
  400301,
  400302,
  400303,
  400304,
  400305,
  400306,
  400307,
  400308,
  400309,
  400321,
  400370,
  400400,
  400401,
  400402,
  400403,
  400404,
  400406,
  400407,
  400408,
  400409,
  400410,
  400412,
  400421,
  400435,
  400444,
  400461,
  400465,
  400476,
  400501,
  400502,
  400503,
  400504,
  400505,
  400506,
  400507,
  400508,
  400509,
  400512,
  400514,
  400515,
  400547,
  400550,
  400551,
  400564,
  400566,
  400598,
  400600,
  400601,
  400602,
  400603,
  400604,
  400605,
  400606,
  400607,
  400608,
  400609,
  400610,
  400611,
  400612,
  400613,
  400614,
  400615,
  400616,
  400617,
  400618,
  400619,
  400624,
  400625,
  400628,
  400639,
  400641,
  400642,
  400644,
  400650,
  400651,
  400653,
  400661,
  400662,
  400664,
  400665,
  400666,
  400667,
  400668,
  400670,
  400672,
  400674,
  400675,
  400679,
  400680,
  400687,
  400688,
  400692,
  400696,
  400699,
  400700,
  400701,
  400703,
  400705,
  400706,
  400708,
  400709,
  400710,
  400711,
  400713,
  400714,
  400715,
  400716,
  400718,
  400720,
  400721,
  400722,
  400725,
  400731,
  400750,
  400760,
  400761,
  400763,
  400765,
  400768,
  400770,
  400773,
  400777,
  400778,
  400801,
  400802,
  400804,
  400806,
  400810,
  400812,
  400814,
  400815,
  400842,
  400882,
  400903,
  400904,
  400905,
  400906,
  400907,
  400908,
  400926,
  401000,
  401002,
  401003,
  401004,
  401005,
  401006,
  401007,
  401010,
  401012,
  401013,
  401014,
  401015,
  401016,
  401017,
  401026,
  401028,
  401029,
  401030,
  401033,
  401041,
  401046,
  401047,
  401064,
  401099,
  401100,
  401101,
  401102,
  401103,
  401104,
  401105,
  401106,
  401107,
  401108,
  401109,
  401112,
  401115,
  401123,
  401127,
  401130,
  401140,
  401151,
  401167,
  401200,
  401201,
  401202,
  401203,
  401204,
  401205,
  401206,
  401207,
  401208,
  401209,
  401210,
  401211,
  401212,
  401214,
  401218,
  401222,
  401224,
  401301,
  401302,
  401303,
  401304,
  401305,
  401306,
  401308,
  401309,
  401311,
  401320,
  401383,
  401401,
  401402,
  401403,
  401404,
  401405,
  401406,
  401407,
  401409,
  401501,
  401502,
  401503,
  401504,
  401505,
  401506,
  401507,
  401508,
  401546,
  401596,
  401601,
  401602,
  401603,
  401604,
  401605,
  401606,
  401607,
  401608,
  401609,
  401610,
  401614,
  401630,
  401701,
  401702,
  401703,
  401704,
  401705,
  401706,
  401707,
  401708,
  401709,
  401771,
  401803,
  401806,
  401809,
  401835,
  401903,
  402001,
  402010,
  402054,
  402063,
  402067,
  402071,
  402156,
  402212,
  402293,
  402501,
  402902,
  403014,
  403772,
  404008,
  404013,
  404105,
  404303,
  404501,
  404708,
  406017,
  406025,
  406051,
  406062,
  406066,
  406101,
  406102,
  406104,
  406206,
  406209,
  406601,
  406605,
  406606,
  406607,
  406615,
  406619,
  406701,
  406706,
  406708,
  407005,
  407601,
  407703,
  407707,
  408070,
  408209,
  408210,
  408410,
  408703,
  409117,
  409701,
  410001,
  410002,
  410003,
  410005,
  410006,
  410010,
  410011,
  410013,
  410014,
  410015,
  410016,
  410017,
  410018,
  410020,
  410021,
  410022,
  410025,
  410026,
  410027,
  410028,
  410029,
  410030,
  410032,
  410033,
  410034,
  410037,
  410038,
  410039,
  410040,
  410041,
  410042,
  410043,
  410044,
  410045,
  410046,
  410047,
  410048,
  410051,
  410052,
  410056,
  410057,
  410059,
  410061,
  410062,
  410065,
  410067,
  410068,
  410069,
  410074,
  410089,
  410093,
  410095,
  410103,
  410104,
  410105,
  410106,
  410107,
  410108,
  410109,
  410110,
  410112,
  410114,
  410115,
  410120,
  410141,
  410200,
  410210,
  410218,
  410219,
  410223,
  410226,
  410229,
  410251,
  410260,
  410270,
  410288,
  410301,
  410302,
  410303,
  410305,
  410306,
  410307,
  410308,
  410309,
  410310,
  410328,
  410335,
  410339,
  410400,
  410401,
  410402,
  410403,
  410405,
  410406,
  410407,
  410415,
  410420,
  410422,
  410501,
  410502,
  410503,
  410504,
  410505,
  410506,
  410507,
  410508,
  410509,
  410510,
  410511,
  410512,
  410513,
  410515,
  410516,
  410567,
  410600,
  410601,
  410602,
  410605,
  410608,
  410612,
  410641,
  410701,
  410702,
  410705,
  410708,
  410709,
  410711,
  410714,
  410801,
  410809,
  410813,
  410905,
  411001,
  411002,
  411003,
  411004,
  411005,
  411006,
  411007,
  411008,
  411009,
  411010,
  411011,
  411012,
  411013,
  411014,
  411015,
  411016,
  411017,
  411018,
  411019,
  411020,
  411021,
  411022,
  411023,
  411024,
  411025,
  411026,
  411027,
  411028,
  411029,
  411030,
  411031,
  411032,
  411033,
  411034,
  411035,
  411036,
  411037,
  411038,
  411039,
  411040,
  411041,
  411042,
  411043,
  411044,
  411045,
  411046,
  411047,
  411048,
  411049,
  411050,
  411051,
  411052,
  411053,
  411054,
  411055,
  411056,
  411057,
  411058,
  411059,
  411060,
  411061,
  411062,
  411063,
  411064,
  411065,
  411066,
  411067,
  411068,
  411069,
  411070,
  411071,
  411072,
  411073,
  411074,
  411075,
  411076,
  411077,
  411078,
  411080,
  411082,
  411083,
  411085,
  411086,
  411088,
  411089,
  411090,
  411091,
  411093,
  411095,
  411096,
  411097,
  411101,
  411102,
  411103,
  411104,
  411105,
  411106,
  411107,
  411108,
  411109,
  411110,
  411112,
  411114,
  411115,
  411119,
  411122,
  411125,
  411151,
  411157,
  411201,
  411202,
  411203,
  411204,
  411205,
  411206,
  411207,
  411208,
  411209,
  411210,
  411211,
  411213,
  411214,
  411216,
  411218,
  411220,
  411237,
  411239,
  411242,
  411301,
  411305,
  411306,
  411307,
  411308,
  411315,
  411401,
  411403,
  411405,
  411432,
  411457,
  411502,
  411503,
  411506,
  411507,
  411508,
  411518,
  411600,
  411601,
  411602,
  411603,
  411604,
  411607,
  411614,
  411619,
  411630,
  411661,
  411701,
  411803,
  411804,
  411842,
  411910,
  411957,
  411982,
  412000,
  412001,
  412003,
  412004,
  412005,
  412006,
  412007,
  412009,
  412010,
  412011,
  412012,
  412013,
  412014,
  412015,
  412016,
  412017,
  412018,
  412020,
  412021,
  412023,
  412026,
  412028,
  412033,
  412035,
  412036,
  412037,
  412038,
  412040,
  412041,
  412042,
  412043,
  412047,
  412057,
  412061,
  412062,
  412064,
  412066,
  412100,
  412101,
  412102,
  412103,
  412104,
  412105,
  412106,
  412107,
  412108,
  412109,
  412110,
  412111,
  412112,
  412113,
  412114,
  412115,
  412116,
  412118,
  412119,
  412120,
  412130,
  412144,
  412200,
  412201,
  412202,
  412203,
  412204,
  412205,
  412206,
  412207,
  412208,
  412209,
  412210,
  412211,
  412212,
  412213,
  412214,
  412215,
  412216,
  412217,
  412218,
  412219,
  412220,
  412221,
  412224,
  412226,
  412228,
  412230,
  412240,
  412251,
  412291,
  412300,
  412301,
  412302,
  412303,
  412304,
  412305,
  412306,
  412307,
  412308,
  412309,
  412310,
  412311,
  412312,
  412313,
  412316,
  412317,
  412318,
  412348,
  412360,
  412401,
  412402,
  412403,
  412404,
  412405,
  412406,
  412407,
  412408,
  412409,
  412410,
  412411,
  412412,
  412415,
  412502,
  412503,
  412505,
  412507,
  412508,
  412510,
  412601,
  412605,
  412608,
  412708,
  412718,
  412807,
  412808,
  412815,
  412830,
  412838,
  412888,
  412902,
  412905,
  412909,
  413011,
  413013,
  413022,
  413030,
  413100,
  413102,
  413103,
  413104,
  413105,
  413106,
  413110,
  413114,
  413115,
  413116,
  413117,
  413120,
  413124,
  413130,
  413131,
  413132,
  413133,
  413141,
  413151,
  413328,
  413337,
  413801,
  413802,
  413803,
  413808,
  413809,
  413811,
  414041,
  414044,
  414115,
  414221,
  414300,
  414517,
  415000,
  415006,
  415008,
  415009,
  415101,
  415219,
  415503,
  415707,
  416000,
  416001,
  416002,
  416003,
  416004,
  416005,
  416006,
  416007,
  416008,
  416009,
  416010,
  416011,
  416012,
  416013,
  416015,
  416020,
  416022,
  416057,
  416101,
  416102,
  416103,
  416104,
  416105,
  416106,
  416107,
  416108,
  416109,
  416110,
  416111,
  416112,
  416113,
  416114,
  416115,
  416116,
  416117,
  416118,
  416119,
  416120,
  416121,
  416122,
  416123,
  416124,
  416125,
  416126,
  416127,
  416128,
  416129,
  416130,
  416131,
  416133,
  416134,
  416135,
  416136,
  416137,
  416138,
  416139,
  416140,
  416141,
  416143,
  416144,
  416146,
  416150,
  416161,
  416165,
  416166,
  416199,
  416200,
  416201,
  416202,
  416203,
  416204,
  416205,
  416206,
  416207,
  416208,
  416209,
  416210,
  416211,
  416212,
  416213,
  416214,
  416215,
  416216,
  416217,
  416218,
  416219,
  416220,
  416221,
  416222,
  416223,
  416224,
  416225,
  416226,
  416228,
  416229,
  416230,
  416231,
  416232,
  416234,
  416235,
  416236,
  416238,
  416249,
  416294,
  416318,
  416323,
  416346,
  416501,
  416502,
  416503,
  416504,
  416505,
  416506,
  416507,
  416508,
  416509,
  416526,
  416527,
  416532,
  416536,
  416544,
  416551,
  416552,
  416557,
  416652,
  417013,
  417027,
  417028,
  417119,
  418102,
  418205,
  418206,
  418209,
  418213,
  418234,
  418501,
  418509,
  418801,
  420001,
  420002,
  420003,
  420005,
  420006,
  420007,
  420009,
  420010,
  420017,
  420020,
  420022,
  420031,
  420042,
  420059,
  420101,
  420106,
  420201,
  420206,
  420208,
  420209,
  420212,
  420220,
  420301,
  420302,
  420306,
  420317,
  420503,
  420517,
  420602,
  420990,
  421000,
  421001,
  421002,
  421003,
  421004,
  421005,
  421006,
  421007,
  421010,
  421015,
  421017,
  421020,
  421021,
  421022,
  421031,
  421032,
  421050,
  421056,
  421062,
  421067,
  421101,
  421102,
  421103,
  421104,
  421105,
  421106,
  421108,
  421110,
  421120,
  421130,
  421200,
  421201,
  421202,
  421203,
  421204,
  421205,
  421206,
  421207,
  421208,
  421210,
  421211,
  421212,
  421213,
  421250,
  421251,
  421261,
  421291,
  421301,
  421302,
  421303,
  421304,
  421305,
  421306,
  421307,
  421308,
  421311,
  421312,
  421317,
  421320,
  421401,
  421402,
  421403,
  421404,
  421411,
  421420,
  421500,
  421501,
  421502,
  421503,
  421504,
  421505,
  421506,
  421507,
  421509,
  421601,
  421602,
  421603,
  421604,
  421605,
  421606,
  421607,
  421609,
  421610,
  421615,
  421624,
  421631,
  421702,
  421703,
  421705,
  421710,
  421801,
  421806,
  421902,
  422001,
  422002,
  422003,
  422004,
  422005,
  422006,
  422007,
  422008,
  422009,
  422010,
  422011,
  422012,
  422013,
  422015,
  422016,
  422017,
  422018,
  422022,
  422023,
  422026,
  422027,
  422029,
  422040,
  422053,
  422100,
  422101,
  422102,
  422103,
  422104,
  422105,
  422112,
  422113,
  422114,
  422130,
  422133,
  422201,
  422202,
  422203,
  422204,
  422205,
  422206,
  422207,
  422208,
  422209,
  422210,
  422211,
  422212,
  422213,
  422214,
  422215,
  422221,
  422222,
  422223,
  422297,
  422301,
  422302,
  422303,
  422304,
  422305,
  422306,
  422308,
  422311,
  422401,
  422402,
  422403,
  422501,
  422502,
  422505,
  422506,
  422606,
  422701,
  423021,
  423101,
  423102,
  423103,
  423104,
  423105,
  423106,
  423108,
  423110,
  423111,
  423117,
  423130,
  423201,
  423202,
  423203,
  423204,
  423205,
  423206,
  423208,
  423212,
  423213,
  423301,
  423302,
  423303,
  423401,
  423402,
  423403,
  423501,
  423502,
  423801,
  424008,
  424109,
  424403,
  424501,
  426001,
  426072,
  426101,
  426234,
  427001,
  427301,
  428002,
  428601,
  429302,
  430005,
  430043,
  430050,
  431017,
  431021,
  431033,
  431163,
  431301,
  431302,
  431306,
  431431,
  431587,
  431617,
  431622,
  431648,
  432001,
  432510,
  433203,
  433401,
  434308,
  434314,
  434401,
  434423,
  435310,
  437333,
  437620,
  440000,
  440001,
  440002,
  440003,
  440004,
  440005,
  440006,
  440007,
  440008,
  440009,
  440010,
  440011,
  440012,
  440013,
  440014,
  440015,
  440016,
  440017,
  440018,
  440019,
  440020,
  440021,
  440022,
  440023,
  440024,
  440025,
  440026,
  440027,
  440028,
  440029,
  440030,
  440031,
  440032,
  440033,
  440034,
  440035,
  440036,
  440037,
  440038,
  440039,
  440041,
  440043,
  440044,
  440049,
  440050,
  440053,
  440059,
  440063,
  440064,
  440066,
  440069,
  440070,
  440072,
  440073,
  440075,
  440077,
  440083,
  440095,
  440099,
  440111,
  440116,
  440123,
  440126,
  440128,
  440130,
  440208,
  440210,
  440218,
  440229,
  440502,
  440904,
  441001,
  441002,
  441003,
  441005,
  441006,
  441010,
  441012,
  441014,
  441017,
  441022,
  441026,
  441030,
  441050,
  441100,
  441101,
  441102,
  441103,
  441104,
  441105,
  441106,
  441107,
  441108,
  441109,
  441110,
  441111,
  441112,
  441113,
  441114,
  441115,
  441116,
  441117,
  441118,
  441122,
  441125,
  441126,
  441128,
  441130,
  441134,
  441201,
  441202,
  441203,
  441204,
  441210,
  441214,
  441220,
  441226,
  441301,
  441302,
  441303,
  441304,
  441305,
  441306,
  441401,
  441404,
  441405,
  441406,
  441407,
  441408,
  441409,
  441501,
  441502,
  441503,
  441504,
  441510,
  441603,
  441604,
  441606,
  442012,
  442034,
  442123,
  442210,
  442542,
  442725,
  444009,
  444016,
  444062,
  444108,
  444830,
  444990,
  447002,
  450007,
  450010,
  450015,
  450016,
  450020,
  450021,
  450201,
  451020,
  451510,
  452000,
  452001,
  452002,
  452003,
  452004,
  452005,
  452006,
  452007,
  452008,
  452009,
  452010,
  452011,
  452012,
  452013,
  452014,
  452015,
  452016,
  452017,
  452018,
  452019,
  452020,
  452021,
  452022,
  452023,
  452025,
  452026,
  452036,
  452038,
  452040,
  452042,
  452046,
  452050,
  452055,
  452056,
  452075,
  452081,
  452106,
  452201,
  452205,
  452216,
  452401,
  452441,
  452452,
  452551,
  453000,
  453001,
  453002,
  453011,
  453111,
  453112,
  453115,
  453117,
  453137,
  453220,
  453235,
  453236,
  453331,
  453332,
  453334,
  453339,
  453436,
  453441,
  453445,
  453446,
  453447,
  453448,
  453455,
  453551,
  453552,
  453556,
  453557,
  453576,
  453661,
  453771,
  454010,
  454444,
  454520,
  454744,
  454771,
  454776,
  454779,
  455053,
  455200,
  455201,
  456101,
  457474,
  457477,
  457547,
  457575,
  457745,
  458010,
  458011,
  458015,
  460003,
  460016,
  460024,
  460201,
  460210,
  461011,
  461102,
  462000,
  462001,
  462002,
  462003,
  462004,
  462006,
  462007,
  462008,
  462009,
  462010,
  462011,
  462012,
  462013,
  462015,
  462016,
  462017,
  462018,
  462019,
  462021,
  462022,
  462023,
  462024,
  462025,
  462026,
  462027,
  462028,
  462029,
  462030,
  462031,
  462032,
  462033,
  462034,
  462035,
  462036,
  462037,
  462038,
  462039,
  462041,
  462042,
  462043,
  462044,
  462045,
  462046,
  462047,
  462048,
  462055,
  462060,
  462066,
  462080,
  462101,
  462106,
  462191,
  462402,
  462420,
  462551,
  463000,
  463038,
  463101,
  463106,
  463111,
  463116,
  463120,
  463128,
  463191,
  463691,
  463693,
  463921,
  464685,
  464740,
  465449,
  465537,
  465625,
  465867,
  466006,
  466611,
  472007,
  473000,
  473330,
  473331,
  473335,
  473338,
  473440,
  473443,
  473446,
  473885,
  476594,
  477004,
  480016,
  485016,
  486980,
  952010,
  360009,
  360015,
  360038,
  360056,
  360058,
  360059,
  360061,
  360064,
  361047,
  362007,
  362008,
  362016,
  362405,
  362445,
  362449,
  362740,
  363610,
  367002,
  368324,
  370013,
  370051,
  370104,
  370206,
  371472,
  372534,
  372721,
  372729,
  377370,
  380000,
  380001,
  380002,
  380003,
  380004,
  380005,
  380006,
  380007,
  380008,
  380009,
  380010,
  380011,
  380012,
  380013,
  380014,
  380015,
  380016,
  380017,
  380018,
  380019,
  380020,
  380021,
  380022,
  380023,
  380024,
  380025,
  380026,
  380027,
  380028,
  380029,
  380030,
  380031,
  380032,
  380033,
  380034,
  380035,
  380037,
  380038,
  380039,
  380040,
  380041,
  380042,
  380043,
  380045,
  380046,
  380048,
  380049,
  380050,
  380051,
  380052,
  380053,
  380054,
  380055,
  380056,
  380057,
  380058,
  380059,
  380060,
  380061,
  380062,
  380063,
  380064,
  380065,
  380067,
  380068,
  380070,
  380072,
  380073,
  380074,
  380075,
  380076,
  380078,
  380079,
  380081,
  380084,
  380086,
  380094,
  380099,
  380210,
  380213,
  380220,
  380221,
  380251,
  380340,
  380355,
  380405,
  380470,
  380481,
  380606,
  380626,
  380776,
  381007,
  381073,
  381120,
  381130,
  381214,
  381225,
  381230,
  381322,
  381415,
  381418,
  381470,
  381530,
  381710,
  381721,
  381729,
  382003,
  382004,
  382018,
  382020,
  382040,
  382045,
  382049,
  382052,
  382056,
  382061,
  382100,
  382105,
  382110,
  382113,
  382115,
  382117,
  382120,
  382123,
  382140,
  382145,
  382150,
  382158,
  382160,
  382170,
  382171,
  382201,
  382206,
  382210,
  382212,
  382213,
  382215,
  382217,
  382218,
  382219,
  382220,
  382223,
  382225,
  382230,
  382240,
  382243,
  382250,
  382255,
  382260,
  382265,
  382270,
  382276,
  382280,
  382300,
  382310,
  382313,
  382316,
  382317,
  382322,
  382323,
  382324,
  382325,
  382326,
  382330,
  382335,
  382336,
  382338,
  382340,
  382341,
  382343,
  382344,
  382345,
  382346,
  382347,
  382350,
  382352,
  382353,
  382360,
  382380,
  382381,
  382395,
  382400,
  382401,
  382402,
  382403,
  382405,
  382408,
  382409,
  382410,
  382413,
  382414,
  382415,
  382417,
  382418,
  382419,
  382420,
  382424,
  382425,
  382426,
  382427,
  382429,
  382430,
  382431,
  382433,
  382435,
  382436,
  382438,
  382440,
  382441,
  382442,
  382443,
  382444,
  382445,
  382446,
  382447,
  382448,
  382449,
  382450,
  382451,
  382452,
  382455,
  382458,
  382463,
  382465,
  382468,
  382470,
  382471,
  382472,
  382473,
  382475,
  382476,
  382477,
  382478,
  382479,
  382480,
  382481,
  382482,
  382483,
  382484,
  382485,
  382486,
  382488,
  382490,
  382493,
  382495,
  382504,
  382505,
  382530,
  382540,
  382545,
  382550,
  382605,
  382615,
  382670,
  382703,
  382713,
  382717,
  382722,
  382727,
  382728,
  382741,
  382752,
  382824,
  382885,
  382908,
  382924,
  382930,
  382940,
  383004,
  383025,
  383040,
  383041,
  383050,
  383405,
  383480,
  383481,
  383621,
  383721,
  384004,
  384006,
  384012,
  384218,
  384224,
  384401,
  384405,
  384424,
  384440,
  384460,
  384505,
  384570,
  385004,
  385005,
  385023,
  385050,
  385415,
  385475,
  386001,
  386058,
  387007,
  387420,
  387422,
  387443,
  387478,
  387480,
  387810,
  387870,
  388443,
  388801,
  388865,
  389006,
  389059,
  389331,
  390028,
  390052,
  390054,
  390095,
  390150,
  390311,
  391001,
  391351,
  391458,
  392025,
  392534,
  393000,
  393405,
  394002,
  394005,
  394015,
  394158,
  394214,
  394229,
  394260,
  381540,
  383626,
  384518,
  387210,
  387220,
  387240,
  387310,
  387315,
  387345,
  388001,
  388002,
  388003,
  388004,
  388007,
  388110,
  388120,
  388121,
  388127,
  388130,
  388140,
  388150,
  388160,
  388170,
  388180,
  388205,
  388210,
  388215,
  388220,
  388240,
  388258,
  388275,
  388305,
  388306,
  388307,
  388308,
  388310,
  388315,
  388320,
  388325,
  388326,
  388327,
  388330,
  388335,
  388340,
  388345,
  388350,
  388355,
  388360,
  388365,
  388370,
  388390,
  388430,
  388431,
  388440,
  388450,
  388460,
  388465,
  388470,
  388480,
  388503,
  388510,
  388520,
  388530,
  388540,
  388543,
  388545,
  388550,
  388560,
  388570,
  388580,
  388590,
  388610,
  388620,
  388625,
  388630,
  388640,
  388714,
  388715,
  388716,
  388770,
  398001,
  362305,
  372740,
  380069,
  380260,
  380420,
  382001,
  382005,
  382006,
  382007,
  382008,
  382009,
  382010,
  382012,
  382015,
  382016,
  382017,
  382019,
  382021,
  382022,
  382024,
  382025,
  382026,
  382028,
  382029,
  382030,
  382032,
  382041,
  382042,
  382043,
  382044,
  382051,
  382070,
  382205,
  382233,
  382271,
  382305,
  382308,
  382315,
  382320,
  382321,
  382333,
  382355,
  382407,
  382421,
  382422,
  382423,
  382428,
  382610,
  382620,
  382630,
  382640,
  382650,
  382655,
  382712,
  382721,
  382724,
  382725,
  382729,
  382735,
  382740,
  382790,
  382810,
  382835,
  382845,
  382855,
  382921,
  392424,
  396151,
  396645,
  360001,
  360002,
  360003,
  360004,
  360005,
  360006,
  360007,
  360008,
  360011,
  360018,
  360019,
  360020,
  360021,
  360022,
  360023,
  360024,
  360025,
  360026,
  360027,
  360030,
  360032,
  360035,
  360036,
  360040,
  360045,
  360050,
  360060,
  360062,
  360066,
  360070,
  360075,
  360080,
  360110,
  360210,
  360311,
  360320,
  360330,
  360360,
  360370,
  360371,
  360375,
  360380,
  360405,
  360410,
  360411,
  360421,
  360430,
  360440,
  360450,
  360452,
  360460,
  360470,
  360480,
  360490,
  360757,
  361015,
  361112,
  361470,
  363621,
  363622,
  363630,
  363636,
  363641,
  363642,
  363650,
  363660,
  363670,
  364465,
  364467,
  364470,
  364485,
  364490,
  365001,
  365480,
  365489,
  368002,
  380410,
  383222,
  385511,
  360016,
  365004,
  365008,
  365009,
  365651,
  366436,
  369062,
  369230,
  369418,
  380501,
  380815,
  381820,
  382013,
  382014,
  382216,
  382342,
  382487,
  382945,
  385007,
  385008,
  385009,
  385018,
  386007,
  390053,
  390056,
  390210,
  391405,
  391407,
  391485,
  391506,
  392024,
  392123,
  392270,
  392410,
  392458,
  392601,
  393004,
  393006,
  393641,
  394001,
  394003,
  394004,
  394007,
  394023,
  394100,
  394101,
  394102,
  394104,
  394105,
  394106,
  394107,
  394110,
  394111,
  394130,
  394140,
  394145,
  394150,
  394155,
  394160,
  394163,
  394170,
  394180,
  394183,
  394185,
  394190,
  394201,
  394205,
  394209,
  394210,
  394211,
  394212,
  394219,
  394220,
  394221,
  394227,
  394230,
  394234,
  394235,
  394237,
  394240,
  394245,
  394246,
  394248,
  394250,
  394267,
  394270,
  394280,
  394289,
  394301,
  394305,
  394306,
  394310,
  394315,
  394317,
  394320,
  394321,
  394325,
  394326,
  394327,
  394330,
  394335,
  394340,
  394350,
  394352,
  394355,
  394360,
  394365,
  394370,
  394375,
  394380,
  394405,
  394410,
  394421,
  394430,
  394440,
  394445,
  394500,
  394510,
  394511,
  394515,
  394516,
  394517,
  394518,
  394520,
  394525,
  394530,
  394540,
  394550,
  394570,
  394601,
  394607,
  394620,
  394623,
  394630,
  394631,
  394633,
  394635,
  394640,
  394641,
  394650,
  394651,
  394655,
  394660,
  394670,
  394680,
  394690,
  394715,
  394716,
  394717,
  394727,
  394730,
  394750,
  394780,
  394925,
  395001,
  395002,
  395003,
  395004,
  395005,
  395006,
  395007,
  395008,
  395009,
  395010,
  395011,
  395012,
  395013,
  395017,
  395019,
  395020,
  395021,
  395022,
  395023,
  395037,
  395039,
  395051,
  395057,
  395066,
  395070,
  395077,
  395081,
  395101,
  395107,
  395110,
  395206,
  395209,
  395210,
  395211,
  395221,
  395230,
  395345,
  395401,
  395410,
  395430,
  395510,
  395601,
  395617,
  395620,
  395651,
  395706,
  395910,
  395927,
  396004,
  396008,
  396192,
  396203,
  396446,
  396510,
  397007,
  397210,
  398002,
  398004,
  398005,
  398007,
  398143,
  398500,
  398505,
  399002,
  399003,
  399004,
  399006,
  399009,
  399010,
  399101,
  399210,
  399327,
  399427,
  369350,
  372750,
  376240,
  380091,
  380350,
  381003,
  381440,
  381510,
  382268,
  382406,
  388626,
  389005,
  389154,
  389350,
  389352,
  389357,
  389950,
  390000,
  390001,
  390002,
  390003,
  390004,
  390005,
  390006,
  390007,
  390008,
  390009,
  390010,
  390011,
  390012,
  390013,
  390014,
  390015,
  390016,
  390017,
  390018,
  390019,
  390020,
  390021,
  390022,
  390023,
  390024,
  390025,
  390026,
  390027,
  390029,
  390031,
  390045,
  390050,
  390055,
  390058,
  390061,
  390070,
  390075,
  390076,
  390078,
  390079,
  390410,
  390740,
  391014,
  391015,
  391101,
  391105,
  391107,
  391110,
  391111,
  391115,
  391125,
  391130,
  391135,
  391137,
  391140,
  391145,
  391150,
  391151,
  391152,
  391155,
  391156,
  391157,
  391160,
  391165,
  391168,
  391169,
  391170,
  391175,
  391210,
  391220,
  391240,
  391242,
  391243,
  391244,
  391246,
  391250,
  391266,
  391310,
  391320,
  391330,
  391331,
  391340,
  391345,
  391346,
  391347,
  391350,
  391401,
  391410,
  391420,
  391421,
  391430,
  391440,
  391442,
  391445,
  391447,
  391450,
  391460,
  391510,
  391514,
  391520,
  391530,
  391535,
  391646,
  391706,
  391740,
  391745,
  391750,
  391751,
  391760,
  391761,
  391770,
  391774,
  391775,
  391776,
  391779,
  391780,
  392159,
  392310,
  392340,
  392440,
  392750,
  392919,
  393105,
  393745,
  394108,
  394285,
  394302,
  394312,
  395650,
  396006,
  396511,
  397440,
  397740,
  398430
];
const residenceTypes = [
  "RENTED",
  "SELF-OWNED",
  "PARENTAL",
  "PG / HOSTEL / SHARE"
];
const educationTypes = [
  "GRADUATE",
  "POSTGRADUATE",
  "UNDERGRADUATE",
  "OTHER"
];
const addressTypes = [
  "RESIDENCE",
  "OFFICE"
];
const uploadStatuses = {
  success: {
    css: 'text-success',
    text: 'Completed Successfully'
  },
  error: {
    css: 'text-danger',
    text: 'Something Went Wrong'
  },
  warning: {
    css: 'text-warning',
    text: 'Action Required'
  },
  progress: {
    css: 'text-info',
    text: 'In Progress'
  },
};
const simplifiedFormFields = {
  id: "UUID",
  appId: "String",
  bankVerificationDate: "Date",
  bankVerificationEpoch: "Epoch",
  bankReceivedDate: "Date",
  bankReceivedEpoch: "Epoch",
  bankResultDate: "Date",
  bankResultEpoch: "Epoch",
  branchId: "Number",
  branchManager: "Username",
  cardType: "String",
  city: "String",
  company: "String",
  createdAt: "Date",
  createdAtEpoch: "Epoch",
  dateOfBirth: "Date",
  documentSubmissionLink: "String",
  email: "Email",
  emailOfficial: "Email",
  employmentStatus: "Radio",
  firstName: "String",
  lastName: "String",
  mobileNumber: "Phone",
  mobileNumberAlternate: "Phone",
  offerType: "Radio",
  owner: "Username",
  pancard: "String",
  previouslyAppliedDate: "Date",
  product: 'UUID',
  referenceNumber: "String",
  remarks: "String",
  status: "UUID",
  statusJustifications: "Array",
  supervisor: "Username",
  submittedBy: "String",
  updatedBy: "Username",
  updatedAt: "String",
  updatedAtEpoch: "Epoch",
  verifiedIncomeProgram: "Boolean",
  version: "Number"
};

const formFields = {
  ...simplifiedFormFields,
  addressLine1: "String",
  addressLine2: "String",
  age: "Number",
  cardMailingAddress: "Radio",
  cibilScore: "Number",
  companyAddressLine1: "String",
  companyAddressLine2: "String",
  companyCity: "String",
  companyLandmark: "String",
  companyListed: "Radio",
  companyStation: "String",
  companyZipcode: "String",
  dateNinetyDays: "Date",
  dateNinetyDaysEpoch: "Epoch",
  documentSubmissionLink: "Url",
  designation: "String",
  education: "Radio",
  extCCAvailableLimit1: "Currency",
  extCCAvailableLimit2: "Currency",
  extCCAvailableLimit3: "Currency",
  extCCAvailableLimit4: "Currency",
  extCCBank1: "String",
  extCCBank2: "String",
  extCCBank3: "String",
  extCCBank4: "String",
  extCCTotalLimit1: "Currency",
  extCCTotalLimit2: "Currency",
  extCCTotalLimit3: "Currency",
  extCCTotalLimit4: "Currency",
  extCCUsingFrom1: "Number",
  extCCUsingFrom2: "Number",
  extCCUsingFrom3: "Number",
  extCCUsingFrom4: "Number",
  fatherName: "String",
  gender: "Radio",
  landmark: "String",
  maritalStatus: "Radio",
  motherName: "String",
  nameDesired: "String",
  netMonthlyIncome: "Currency",
  officeExtension: "Phone",
  officeLandline: "Phone",
  referralBy: "Username",
  residenceType: "Radio",
  station: "String",
  stayingSince: "Number",
  submittedDocuments: "Urls",
  title: "Radio",
  yearsEmployed: "Number",
  zipcode: "String",
  bankStatementPassword1: "Password",
  bankStatementPassword2: "Password",
  bankStatementPassword3: "Password",
  extCCNumber: "Number",
  extCCStatementPassword: "Password",
  salarySlipPassword: "Password",
};

const buttons = [
  {
    extend: 'pageLength',
    text: '<i class="fas fa-filter"></i>',
    titleAttr: 'Number of Rows'
  },
  {
    extend: 'copyHtml5',
    text: '<i class="far fa-copy"></i>',
    titleAttr: 'Copy',
    exportOptions: {
      columns: ':visible'
    }
  },
  {
    extend: 'excelHtml5',
    text: '<i class="far fa-file-excel"></i>',
    titleAttr: 'Excel',
    autoFilter: true,
    exportOptions: {
      columns: ':visible'
    }
  },
  {
    extend: 'csvHtml5',
    text: '<i class="far fa-file-alt"></i>',
    titleAttr: 'CSV',
    exportOptions: {
      columns: ':visible'
    }
  },
  // {
  // 	extend: 'pdfHtml5',
  // 	text: '<i class="far fa-file-pdf"></i>',
  // 	titleAttr: 'PDF',
  // 	exportOptions: {
  // 		columns: ':visible'
  // 	}
  // },
  {
    extend: 'print',
    text: '<i class="fas fa-print"></i>',
    titleAttr: 'Print',
    exportOptions: {
      columns: ':visible'
    }
  },
  {
    extend: 'colvis',
    text: '<i class="fas fa-eye"></i>',
    titleAttr: 'Column Visibility',
    columns: ':not(.noVis)'
  }
];
const barChartColours = [
  {
    color: "rgb(1,0,80, 0.4)",
    border: "rgb(1,0,80, 1)"
  },
  {
    color: "rgb(80,0,80, 0.4)",
    border: "rgb(80,0,80, 1)"
  },
  {
    color: "rgb(134,0,80, 0.4)",
    border: "rgb(134,0,80, 1)"
  },
  {
    color: 'rgb(145,0,135, 0.4)',
    border: 'rgb(145,0,135, 1)'
  },
  {
    color: "rgb(212,0,180, 0.4)",
    border: "rgb(212,0,180, 1)"
  },
  {
    color: "rgb(217,0,70, 0.5)",
    border: "rgb(217,0,70, 1)"
  },
  {
    color: "rgb(180,0,83, 0.5)",
    border: "rgb(180,0,83, 1)"
  },
  {
    color: 'rgb(128,0,0, 0.4)',
    border: 'rgb(128,0,0, 1)'
  },
  {
    color: "rgb(255,55,53, 0.4)",
    border: "rgb(255,55,53, 1)"
  },
  {
    color: "rgb(255,95,35, 0.5)",
    border: "rgb(255,95,35, 1)"
  },
  {
    color: "rgb(255,143,28, 0.5)",
    border: "rgb(255,143,28, 1)"
  },
  {
    color: "rgb(255,191,0, 0.5)",
    border: "rgb(255,191,0, 1)"
  }
];

const chartColours = [
  {
    color: "rgb(146,237,46, 0.4)",
    border: "rgb(146,237,46, 1)"
  },
  {
    color: "rgb(48,209,51, 0.4)",
    border: "rgb(48,209,51, 1)"
  },
  {
    color: "rgb(9,162,82, 0.4)",
    border: "rgb(9,162,82, 1)"
  },
  {
    color: "rgb(2,124,125, 0.4)",
    border: "rgb(2,124,125, 1)"
  },
  {
    color: "rgb(45,68,92, 0.4)",
    border: "rgb(45,68,92, 1)"
  },
  {
    color: 'rgb(67,99,216, 0.4)',
    border: 'rgb(67,99,216, 1)'
  },
  {
    color: 'rgb(0,0,117, 0.4)',
    border: 'rgb(0,0,117, 1)'
  },
  {
    color: "rgb(0,30,58, 0.4)",
    border: "rgb(0,30,58, 1)"
  },
  {
    color: "rgb(31,49,81, 0.4)",
    border: "rgb(31,49,81, 1)"
  },
  {
    color: "rgb(69,81,124, 0.4)",
    border: "rgb(69,81,124, 1)"
  },
  {
    color: "rgb(102,81,145, 0.4)",
    border: "rgb(102,81,145, 1)"
  },
  {
    color: 'rgb(145,30,180, 0.4)',
    border: 'rgb(145,30,180, 1)'
  },
  {
    color: "rgb(160,81,149, 0.4)",
    border: "rgb(160,81,149, 1)"
  },
  {
    color: "rgb(212,80,135, 0.4)",
    border: "rgb(212,80,135, 1)"
  },
  {
    color: "rgb(249,93,106, 0.4)",
    border: "rgb(249,93,106, 1)"
  },
  {
    color: 'rgb(230,25,75, 0.4)',
    border: 'rgb(230,25,75, 1)'
  },
  {
    color: 'rgb(128,0,0, 0.4)',
    border: 'rgb(128,0,0, 1)'
  },
  {
    color: "rgb(249,55,35, 0.4)",
    border: "rgb(249,55,35, 1)"
  },
  {
    color: "rgb(255,124,67, 0.4)",
    border: "rgb(255,124,67, 1)"
  },
  {
    color: "rgb(255,166,0, 0.4)",
    border: "rgb(255,166,0, 1)"
  }
];

export {
  pushNotificationCategories,
  leadFeedbackCategories,
  priorities,
  oclPincodes,
  residenceTypes,
  educationTypes,
  addressTypes,
  formFields,
  simplifiedFormFields,
  buttons,
  barChartColours,
  chartColours,
  uploadStatuses
}
