import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap-datepicker";
import "jquery-uniform";
import Amplify, * as AmplifyModules from "aws-amplify";
import {AmplifyPlugin} from "aws-amplify-vue";
import aws_exports from "./aws-exports";
import router from "./router/router";
import store from "./store";
import Msg from "v-msgx";
import "./registerServiceWorker";
import FontAwesomeIcons from "./helpers/fontAwesomeIcons";
import VeeValidate from 'vee-validate';
import AsyncComputed from 'vue-async-computed';
import ToggleButton from 'vue-js-toggle-button';
import "datatables.net-bs";
import "datatables.net-buttons-bs";
import "datatable-sorting-datetime-moment";
import JSZip from "jszip/dist/jszip.min"
import "datatables.net-buttons/js/buttons.print.min";
import "datatables.net-buttons/js/buttons.html5.min";
import "datatables.net-buttons/js/buttons.colVis.min";
import "datatables.net-select-bs/js/select.bootstrap.min";
// import "datatables.net-responsive/js/dataTables.responsive.min"
// import "datatables.net-rowgroup/js/dataTables.rowGroup.min";

window.JSZip = JSZip;
Vue.config.productionTip = false;

Amplify.configure(aws_exports);

Amplify.configure({
  // To get the AWS Credentials, you need to configure
  // the Auth module with your Cognito Federated Identity Pool
  Auth: {
    identityPoolId: aws_exports.aws_cognito_identity_pool_id,
    region: aws_exports.aws_cognito_region
  },
  Analytics: {
    AWSPinpoint: {
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: false,
      // OPTIONAL -  Amazon Pinpoint App Client ID
      appId: '1e051496243c46febd3e38295a01acf2',
      // OPTIONAL -  Amazon service region
      region: 'us-east-1',
      // OPTIONAL - The limit for failed recording retries.
      resendLimit: 5
    }
  }
});

Vue.use(AsyncComputed);
Vue.use(FontAwesomeIcons);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(Msg);
Vue.use(VeeValidate);
Vue.use(ToggleButton);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
